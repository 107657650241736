import React, { useContext } from 'react';
import './index.css';

import ThemeContext from '../../../../context/ThemeContext';

function OptionGroup({ children }) {
  const { surface } = useContext(ThemeContext);

  return (
    <div style={{ backgroundColor: surface }} className="option-group">
      { children }
    </div>
  );
}

export default OptionGroup;