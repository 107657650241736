import React, { useContext } from 'react';

import Drawer from '../../../components/Drawer';
import OptionGroup from './OptionGroup';

import ThemeContext from '../../../context/ThemeContext';
import OrdersContext from '../context';

function DrawerComponent() {
  const { main, text } = useContext(ThemeContext);
  const { filter, setFilter } = useContext(OrdersContext);

  return (
    <Drawer>
      <h2 style={{ color: text, margin: 0 }}>Filtros</h2>
      <br />

      <OptionGroup>
        {
          [
            { key: 0, label: 'Ativos' },
            { key: 1, label: 'Aguardando confirmação' },
            { key: 2, label: 'Em preparo' },
            { key: 3, label: 'Enviado para entrega' },
            { key: 4, label: 'Recusados' },
            { key: 5, label: 'Cancelados' },
            { key: 6, label: 'Entregues' },
            { key: 7, label: 'Reclamações' },
          ].map(({ key, label }) => (
              <div
                key={key}
                onClick={() => setFilter(key)}
                className="opt-item"
                style={{ cursor: 'pointer', paddingTop: 2, height: 30, color: filter === key ? main : text }}
              >{ label }</div>
          ))
        }
      </OptionGroup>
    </Drawer>
  );
}

export default DrawerComponent;