import { Button, createMuiTheme, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Modal, Switch, TextField, ThemeProvider } from '@material-ui/core';
import React, { useContext, useState } from 'react';

import { useFetch } from '../../../../../../../hooks';

import ThemeContext from '../../../../../../../context/ThemeContext';
import ProductContext from '../../../../../context';
import { OptionGroupContext } from '../index';

function OptionGroupUpdate({ optionGroup }) {
  const { main, mode, soft, surface, text } = useContext(ThemeContext);
  const { select, selected: product } = useContext(ProductContext);
  const { update: visible, changeModal } = useContext(OptionGroupContext);
  const onDismiss = () => changeModal("update", false);

  const theme = createMuiTheme({
    palette: {
      secondary: { main: main.hex() },
      type: mode
    }
  });

  const [title, setTitle] = useState(optionGroup.title);
  const [maxItems, setMaxItems] = useState(optionGroup.maxItems ?? 0);
  const [required, setRequired] = useState(optionGroup.required);
  const [unique, setUnique] = useState(optionGroup.unique);

  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState("");

  const showError = (message = "Algo de errado aconteceu.") => {
    setMessage(message);
    setIsError(true);
  }
  
  const handleUpdate = () => {
    if (title === '') {
      showError("Insira um título");
    }
    
    useFetch.post('/a/opt/grp/update', {
      optionGroupId: optionGroup.optionGroupId,
      title,
      required,
      unique,
      maxItems: unique ? 0 : parseInt(maxItems),
    }, (response) => {
      if (response.code === 'success') {
        let options = product.options;
        let index = product.options.findIndex(item => item.optionGroupId === optionGroup.optionGroupId);

        options[index] = {
          ...options[index],
          title,
          required,
          unique,
          maxItems: unique ? null : parseInt(maxItems)
        };

        select({
          ...product,
          options
        });

        onDismiss();
      } else {
        showError();
      }
    })
  }

  return (
    <ThemeProvider theme={theme}>
      <Modal open={visible} onClose={onDismiss} style={{ outline: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ outline: 'none', backgroundColor: surface.hex(), padding: 20 }}>
          <Dialog open={isError} onClose={() => setIsError(false)}>
            <DialogTitle>Erro</DialogTitle>
            <DialogContent>{ message }</DialogContent>
            <DialogActions>
              <Button onClick={() => setIsError(false)}>
                Ok
              </Button>
            </DialogActions>
          </Dialog>

          {/* content */}

          <TextField
            style={{ width: 300 }}
            label="Novo grupo de opções"
            placeholder="Ex.: Acompanhamentos"
            value={title}
            onChange={e => setTitle(e.target.value)}
            color="secondary"
          />

          <TextField
            style={{ marginLeft: 10, width: 100 }}
            label="Máx"
            type="number"
            placeholder="Quantidade máxima"
            value={maxItems}
            onChange={e => setMaxItems(e.target.value)}
            color="secondary"
          />

          

          <div style={{ marginTop: 20, display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex' }}>
              <FormControl style={{ marginLeft: 20 }}>
                <Switch
                  checked={required}
                  onChange={e => setRequired(e.target.checked)}
                />
                <FormHelperText>Obrigatório</FormHelperText>
              </FormControl>

              <FormControl style={{ marginLeft: 20, display: 'flex', alignItems: 'center' }}>
                <Switch
                  checked={unique}
                  onChange={e => setUnique(e.target.checked)}
                />
                <FormHelperText>Único</FormHelperText>
              </FormControl>
            </div>
            <div>
              <Button onClick={onDismiss}>Cancelar</Button>
              <Button style={{ marginLeft: 10, backgroundColor: soft.hex(), color: text.hex() }} onClick={handleUpdate} variant="contained" disableElevation>Atualizar</Button>
            </div>
          </div>
        </div>
      </Modal>
    </ThemeProvider>
  );
}

export default OptionGroupUpdate;