import React, { useContext, useMemo, useRef } from 'react';
import { PieChart, Pie, Tooltip, Cell } from 'recharts';

import ThemeContext from '../../../../context/ThemeContext';
import StatisticsContext from '../../context';

import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import { colors } from '../colors';

const Weekdays = () => {
  const { muted, surface, text } = useContext(ThemeContext);
  const { orders: contextOrders } = useContext(StatisticsContext);

  const containerRef = useRef();

  const orders = useMemo(() => {
    const response = Object.values(contextOrders.filter((item) => [0,1,2,3].some(status => status === item.status)).reduce((obj, item) => {
      const weekday = format(parseInt(item.createdAt), 'EEEE', { locale: ptBR });

      if (obj[weekday]) {
        obj[weekday].value += 1;
      } else {
        obj[weekday] = {
          name: weekday,
          value: 1
        }
      }

      return obj;
    }, []));

    return response;
  }, [contextOrders]);

  return (
    <div style={{ backgroundColor: surface.hex() }} ref={containerRef} className="circle-two" >
      <div style={{ marginTop: 10, marginBottom: -90, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', }}>
        <h2 style={{ color: text, marginBottom: 15 }}>Melhor dia</h2>
        <span style={{ color: muted }}>Vendas conforme os dias da semana</span>
        <small style={{ color: muted }}>* Passe o mouse para mais detalhes</small>
      </div>

      <PieChart width={240} height={300}>
        <Pie
          data={orders}
          cx={120}
          cy={200}
          innerRadius={60}
          outerRadius={80}
          fill="transparent"
          dataKey="value"
        >
          {
            orders.map((entry, index) => <Cell stroke="transparent" key={`cell-${index}`} fill={colors[index]} />)
          }

        </Pie>
        <Tooltip />
      </PieChart>
    </div>
  );  
}

export default Weekdays;