import React, { useContext } from 'react';
import './styles.css';

import ThemeContext from '../../context/ThemeContext';
import { Scrollbars } from 'react-custom-scrollbars';

import Filter from './Top/Filter';
import Numbers from './Top/Numbers';

import Total from './Charts/Total';
import Products from './Charts/Products';
import Weekdays from './Charts/Weekdays';
import Stats from './Charts/Stats';

import StatisticsContext, { StatisticsContextProvider } from './context';
import Loader from 'react-loader-spinner';

function Statistics() {
  const { background, main } = useContext(ThemeContext);

  return (
    <div style={{ backgroundColor: background, height: '100vh', overflowX: 'hidden' }}>
      <Scrollbars>
        <StatisticsContextProvider>
          <StatisticsContext.Consumer>
            {
              ({ isLoading }) => isLoading ? (
                <div style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Loader
                    width={50}
                    height={50}
                    color={main}
                    type="TailSpin"
                  />
                </div>
              ) : (
                <div className="stats-grid">
                  <Filter />
                  <Numbers />

                  {/* Charts */}
                  <Stats />
                  <Products />
                  <Weekdays />
                  <Total />
                  <div className="empty-space" style={{ height: 200, width: 100 }} />
                </div>
              )
            }
          </StatisticsContext.Consumer>
        
        </StatisticsContextProvider>
      </Scrollbars>
    </div>
  );
}

export default Statistics;