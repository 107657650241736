import React, { createRef, useContext, useState } from 'react';
import { Button, createMuiTheme, Dialog, DialogActions, DialogContent, DialogTitle, Modal, ThemeProvider } from '@material-ui/core';

import './styles.css';

import ThemeContext from '../../../../../context/ThemeContext';
import ProductContext from '../../../context';
import { ProductActionsContext } from '../../index';

import { useFetch } from '../../../../../hooks';

function ProductImage () {
  const { main, mode, soft, surface, text } = useContext(ThemeContext);
  const { select, selected: product, findProducts } = useContext(ProductContext);
  const { image: visible, changeAction } = useContext(ProductActionsContext);
  const onDismiss = () => changeAction("image", false);

  const theme = createMuiTheme({
    palette: {
      background: { default: surface.hex(), paper: surface.hex() },
      secondary: { main: main.hex() },
      type: mode
    }
  });

  const imageRef = createRef();

  const [image, setImage] = useState(product.image);

  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState("");

  const showError = (message = "Algo de errado aconteceu.") => {
    setMessage(message);
    setIsError(true);
  }

  const onChange = () => {
    let file = imageRef.current.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = (e) => {
      setImage(reader.result);
    }
  }

  const handleCreate = () => {
    const data = new FormData();

    data.append("productId", product.productId);
    data.append("title", product.name);
    data.append("description", product.details);
    data.append("price", product.price);
    data.append("sale", product.salePrice);

    if (image !== product.image) {
      const fileData = imageRef.current.files[0];
      data.append("file", fileData);
      data.append("update_image", '1');
    } else {
      data.append("update_image", '0');
    }


    useFetch.postFormData('/a/product/update', data, (response) => {
      if (response.code === 'success') {
        select({ ...product, image });
        findProducts();
        onDismiss();
      } else {
        showError();
      }
    });
  }

  return (
    <ThemeProvider theme={theme}>
      <Modal
        open={visible}
        onClose={onDismiss}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <div style={{ backgroundColor: surface.hex(), padding: 20 }} className="container">
          <Dialog open={isError} onClose={() => setIsError(false)}>
            <DialogTitle>Erro</DialogTitle>
            <DialogContent>{ message }</DialogContent>
            <DialogActions>
              <Button onClick={() => setIsError(false)}>
                Ok
              </Button>
            </DialogActions>
          </Dialog>

          <div className="product-image" style={{ display: 'flex', flexDirection: 'column', width: 220 }}>

            <img src={image} style={{ height: 200, borderRadius: 10, backgroundColor: '#f2f2f2', alignSelf: 'stretch' }} alt="" />
            <input ref={imageRef} onChange={onChange} type="file" name="file" id="file" />
            <label htmlFor="file" style={{ backgroundColor: soft.hex(), color: text.hex(), cursor: 'pointer', marginTop: 20 }}>Selecione uma imagem</label>

          </div>

          <div style={{ marginTop: 20 }}>
            <Button onClick={onDismiss}>Cancelar</Button>
            <Button style={{ marginLeft: 10, backgroundColor: soft.hex(), color: text.hex() }} onClick={handleCreate} variant="contained" disableElevation>Atualizar</Button>
          </div>
        </div>
      </Modal>
    </ThemeProvider>
  );
}

export default ProductImage;