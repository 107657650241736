import React from 'react';

import ContactEditor from './ContactEditor';
import { ContactContextProvider } from './context';

function Andress() {
  return (
    <ContactContextProvider>
      <ContactEditor />
    </ContactContextProvider>
  );
}

export default Andress;