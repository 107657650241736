import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import SocketContext from '../../context/SocketContext';

const OrdersContext = createContext({
  isModalActive: false,
  setIsModalActive: (boolean) => {},

  isLoading: true,

  search: '',
  setSearch: (search) => {},

  filter: null,
  setFilter: (filter) => {},

  orders: [],
  list: [],

  selected: null,
  select: (order) => {},
  findOrders: () => {}
});

export function OrdersContextProvider ({ children }) {
  const { isLoading, findOrders, orders: socketOrders } = useContext(SocketContext);

  const [isModalActive, setIsModalActive] = useState(false);
  const [filter, setFilter] = useState(0);
  const [search, setSearch] = useState("");
  const [list, setList] = useState(socketOrders);
  const [selected, select] = useState(null);

  useEffect(() => setList(socketOrders), [socketOrders]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => search !== "" ? setSearch("") : () => {}, [filter])

  const orders = useMemo(() => {
    let data = [];

    if (filter === 0) {
      data = list.filter(item => ![0, 4, 5].some(status => item.status === status ));
    } else if ([1, 2, 3, 4, 5].some(item => item === filter)) {
      data = list.filter(item => item.status === filter);
    } else if (filter === 6) {
      data = list.filter(item => item.status === 0);
    } else if (filter === 7) {
      data = list.filter(item => item.report !== null);
    }

    if (search !== "") {
      let regex = new RegExp(search, 'i');
      data = list.filter(({ user, identifier, orders }) => {
        return regex.test(user.name) ? regex.test(user.name)
               : regex.test(identifier) ? regex.test(identifier)
               : orders.some((item) => regex.test(item.title));        
      });
    }


    return data;

  }, [filter, search, list]);
  
  console.log(orders);

  return (
    <OrdersContext.Provider value={{ isModalActive, setIsModalActive, isLoading, search, setSearch, filter, setFilter, orders, list, selected, select, findOrders }}>
      { children }
    </OrdersContext.Provider>
  );
}

export default OrdersContext;
