import React, { useContext } from 'react';
import { FormControl, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core';

import ThemeContext from '../../../../context/ThemeContext';
import StatisticsContext from '../../context';

function TopOne() {
  // this components shows on /statistics the orders and products quantity

  const { surface, text } = useContext(ThemeContext);
  const { interval, setInterval } = useContext(StatisticsContext);

  const useStyles = makeStyles({ root: { color: text.hex() }, });
  const classes = useStyles();

  const onChange = (e) => setInterval(e.target.value);

  return (
    <div className="top-one" style={{ color: text.hex(), backgroundColor: surface.hex(), display: 'flex', flexDirection: 'column', paddingLeft: 20, paddingRight: 20 }}>
      <h4>Exibição de dados</h4>

      {/* <ThemeProvider> */}
        <FormControl >
          <InputLabel classes={{ root: classes.root }} id="interval-selector">Selecione um intervalo</InputLabel>
          <Select value={interval} onChange={onChange} labelId="interval-selector" classes={{ root: classes.root }}>
            <MenuItem value={1}>Este dia</MenuItem>
            <MenuItem value={2}>Últimos 7 dias</MenuItem>
            <MenuItem value={3}>Últimos 30 dias</MenuItem>
            <MenuItem value={4}>Último ano</MenuItem>
            <MenuItem value={0}>Tudo</MenuItem>
          </Select>
        </FormControl>
      {/* </ThemeProvider> */}
    </div>
  );
}

export default TopOne;