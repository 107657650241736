import React, { useContext } from 'react';
import { Button, createMuiTheme, Dialog, DialogActions, DialogContent, DialogTitle, ThemeProvider } from '@material-ui/core';

import ThemeContext from '../../../context/ThemeContext';
import ProductsContext from '../context';

import { useFetch } from '../../../hooks';

function CategoryDelete({ category, visible, onDismiss }) {
  const { main, mode, soft, surface, text } = useContext(ThemeContext);
  const { findProducts } = useContext(ProductsContext);

  const theme = createMuiTheme({
    palette: {
      background: { default: surface.hex(), paper: surface.hex() },
      secondary: { main: main.hex() },
      type: mode
    }
  });

  const handleDelete = () => {
    useFetch.delete('/a/cat/' + category.categoryId, (data) => {
      if (data.code === 'success') {
        findProducts();
        onDismiss();
      } else {
        alert("Algum erro ocorreu durante a exclusão");
      }
    })
  }

  return (
    <ThemeProvider theme={theme}>
      <Dialog open={visible} onClose={onDismiss}>
        <DialogTitle>Certeza que deseja excluir essa categoria?</DialogTitle>
        <DialogContent>
          Todos os produtos cadastrados em {category?.name ?? "---"} serão perdidos.
          Caso não queira perder algum produto, você pode transferi-lo para
          uma outra categoria antes de exclui-la.
        </DialogContent>
        <DialogActions>
          <Button onClick={onDismiss}>Cancelar</Button>
          <Button style={{ backgroundColor: soft.hex(), color: text.hex() }} onClick={handleDelete} disableElevation variant="contained">Sim, excluir</Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}

export default CategoryDelete;
