import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Modal, Switch, TextField } from '@material-ui/core';
import React, { useContext, useState } from 'react';

import ThemeContext from '../../../../../../../../context/ThemeContext';
import ProductContext from '../../../../../../context';

import { useFetch } from '../../../../../../../../hooks';

import { currencyFormat } from '../../../../../../../../utils';

function OptionItemUpdate({ optionGroupId, optionItem, visible, onDismiss }) {
  
  const { surface, soft, text } = useContext(ThemeContext);
  const { select, selected, findProducts } = useContext(ProductContext);

  const [title, setTitle] = useState(optionItem.name);
  const [price, setPrice] = useState(currencyFormat(optionItem.addValue));
  const [free, setFree] = useState(false);

  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState("");

  const showError = (message = "Algo de errado aconteceu.") => {
    setMessage(message);
    setIsError(true);
  }

  const handleUpdate = () => {
    let priceValue = 0;

    if (title.trim() === "") {
      showError("Insira um título");
    }

    if (!free) {
      if (price.trim() === "") {
        showError("Valor inválido");
        return;
      }
      try {
        priceValue = parseFloat(price.replace('R$ ', '').replace(',', '.'));
      } catch (e) {
        showError("Valor inválido");
        return;
      }
    }

    useFetch.post('/a/opt/itm/update', {
      optionItemId: optionItem.optionItemId,
      title,
      price: priceValue,
    }, (response) => {
      if (response.code === 'success') {
        let product = selected;
        let optionGroupIndex = product.options.findIndex((item) => item.optionGroupId === optionGroupId);
        let optionItemIndex = product.options[optionGroupIndex].options.findIndex((item) => item.optionItemId === optionItem.optionItemId); 

        product.options[optionGroupIndex].options[optionItemIndex] = {
          ...optionItem,
          name: title,
          addValue: priceValue
        }

        select(product);
        findProducts();
        onDismiss();
      } else {
        showError();
      }
    });
  }
  
  return (
    <Modal open={visible} onClose={() => onDismiss()} style={{ outline: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <div style={{ outline: 'none', backgroundColor: surface.hex(), padding: 20 }}>
        <Dialog open={isError} onClose={() => setIsError(false)}>
          <DialogTitle>Erro</DialogTitle>
          <DialogContent>{ message }</DialogContent>
          <DialogActions>
            <Button onClick={() => setIsError(false)}>
              Ok
            </Button>
          </DialogActions>
        </Dialog>

        {/* content */}
        <div>
          <TextField
            style={{ width: 300 }}
            label={`Alterar título`}
            placeholder="Ex.: Batata frita"
            value={title}
            onChange={e => setTitle(e.target.value)}
            color="secondary"
          />
        </div>

        <div style={{ display: 'flex', marginTop: 15 }}>
          <TextField
            disabled={free}
            style={{ flex: 1 }}
            label="Valor"
            placeholder="Apenas números"
            value={price}
            onChange={e => setPrice(currencyFormat(e.target.value))}
            color="secondary"
          />

          <FormControl style={{ marginLeft: 20 }}>
            <Switch
              checked={free}
              onChange={e => setFree(e.target.checked)}
            />
            <FormHelperText>Gratuito</FormHelperText>
          </FormControl>
        </div>
        <div style={{ marginTop: 20, display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
          <Button onClick={onDismiss}>Cancelar</Button>
          <Button style={{ marginLeft: 10, backgroundColor: soft.hex(), color: text.hex() }} onClick={handleUpdate} variant="contained" disableElevation>Atualizar</Button>
        </div>
      </div>
    </Modal>
  );
}

export default OptionItemUpdate;