import React, { useContext } from 'react';

import ThemeContext from '../../../../context/ThemeContext';
import OrdersContext from '../../context';

function Report() {
  const { background, text } = useContext(ThemeContext);
  const { selected } = useContext(OrdersContext);
  
  if (!selected.report) return <></>;

  return (
    <div style={{ backgroundColor: background.hex(), color: text.hex(), borderRadius: 10, padding: 20 }}>
      <h2>Reclamações</h2>
      <p>
        {selected.report}
      </p>
    </div>
  );
}

export default Report;