import React, { useContext, useMemo } from 'react';
import ThemeContext from '../../../../context/ThemeContext';
import OrdersContext from '../../context';

function Badge ({ children }) {
  const { soft, text } = useContext(ThemeContext);
  return <span style={{ marginLeft: 10, backgroundColor: soft.hex(), color: text.hex(), padding: 3, paddingLeft: 10, paddingRight: 10, borderRadius: 10 }}>{ children }</span>;
}

function InfoList() {
  const { list } = useContext(OrdersContext);

  const status = useMemo(() => ({
    waiting: list.filter((item) => item.status === 1).length,
    preparing: list.filter((item) => item.status === 2).length,
    sending: list.filter((item) => item.status === 3).length,
  }), [list]);

  const { waiting, preparing, sending } = status;

  return (
    <div style={{ display: 'flex', marginBottom: 20 }}>
      { waiting  ? <Badge>{ waiting === 1 ? '1 em espera' : `${waiting} em espera` }</Badge> : <></> }
      { preparing ? <Badge>{ preparing === 1 ? '1 em preparo': `${preparing} em preparo` }</Badge> : <></> }
      { sending  ? <Badge>{ sending === 1 ? '1 sendo enviado' : `${sending} sendo enviados` }</Badge> : <></> }
    </div>
  );
}

export default InfoList;