import React, { createContext, useContext, useState } from 'react';
import './index.css';
import OptionItem from './OptionItem';

import OptionItemCreate from './OptionItemCreate';
import OptionGroupDelete from './OptionGroupDelete';
import OptionGroupUpdate from './OptionGroupUpdate';

import IconButton from '@material-ui/core/IconButton';
import { Icon } from '@mdi/react';
import { mdiDeleteOutline, mdiPencilOutline, mdiPlus } from '@mdi/js';

import ThemeContext from '../../../../../../context/ThemeContext';

export const OptionGroupContext = createContext({
  create: false,
  delete: false,
  update: false,

  changeModal: (key, value) => {},
});

function OptionGroup({ style, optionGroup }) {
  const { text, soft } = useContext(ThemeContext);

  const [controller, setController] = useState({
    create: false,
    delete: false,
    update: false,
  })

  const changeModal = (key, value) => setController({ ...controller, [key]: value, }); 

  return (
    <OptionGroupContext.Provider value={{...controller, changeModal}}>
      <div style={style}>
        <OptionItemCreate optionGroup={optionGroup} />
        <OptionGroupDelete optionGroup={optionGroup} />
        <OptionGroupUpdate optionGroup={optionGroup} />

        <div className="optionGroup" style={{ display: 'flex', alignItems: 'center' }}>
          <h2 className="optionGroupTitle">
            { optionGroup.title }
          </h2>
          
          <div style={{ backgroundColor: soft.hex(), marginLeft: 25, display: 'flex', alignItems: 'center', borderRadius: 50, paddingLeft: 10, paddingRight: 10 }}>
            <IconButton onClick={() => changeModal("create", true)} size="small">
              <Icon path={mdiPlus} title="" size={1} color={text.hex()} />
            </IconButton>
            <IconButton onClick={() => changeModal("update", true)} size="small" style={{ padding: 5 }}>
              <Icon path={mdiPencilOutline} title="" size={1} color={text.hex()} />
            </IconButton>
            <IconButton onClick={() => changeModal("delete", true)} size="small">
              <Icon path={mdiDeleteOutline} title="" size={1} color={text.hex()} />
            </IconButton>
          </div>
        </div>

        {
          [
            optionGroup.unique,
            optionGroup.required,
            optionGroup.maxItems
          ].some(item => item) ? (
            <div style={{ display: 'flex' }}>
              <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 20, borderRadius: 4, backgroundColor: 'rgba(0, 0, 0, 0.1)', padding: 8 }}>
                <small>{ optionGroup.unique ? 'Único' : '' }</small>
                <small>{ optionGroup.required ? 'Obrigatório' : '' }</small>
                <small>{ optionGroup.maxItems ? `(max ${optionGroup.maxItems})` : '' }</small>
              </div>
            </div>
          ) : <></>
        }

        <div>
          {
            optionGroup.options.map((optionItem) => (
              <OptionItem key={optionItem.optionItemId} optionGroupId={optionGroup.optionGroupId} optionItem={optionItem} />
            ))
          }
        </div>
      </div>
    </OptionGroupContext.Provider>
  );
}

export default OptionGroup;