import React from 'react';

import Schedule from './Schedule';
import ForceStop from './ForceStop';

function General() {
  return (
    <div>
      <Schedule />
      <ForceStop />
    </div>
  );
}

export default General;