import React, { useContext } from 'react';
import ThemeContext from '../../../../../../../context/ThemeContext';

import ContactContext from '../../context';

function ContactItem({ item }) {
  const { main, muted } = useContext(ThemeContext);
  const { select } = useContext(ContactContext);

  const { title, contacts } = item;

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <p style={{ color: muted.hex() }}>{ title }</p>
        <small
          onClick={() => select(item)}
          style={{ color: main.hex(), cursor: 'pointer', marginLeft: 10 }}
        >editar</small>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {
          contacts.map(contact => (
            <small key={contact + Math.random()}>{ contact }</small>
          ))
        }
      </div>
    </div>
  );
}

export default ContactItem;
