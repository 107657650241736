import React, { createContext, useMemo, useState } from 'react';
import Color from 'color';

const ThemeContext = createContext({
  main: null,
  accent: null,

  background: null,
  muted: null,
  surface: null,
  soft: null,
  text: null,
  mode: 'light',

  setMainColor: (color) => {},
  setAccentColor: (color) => {},
  setThemeVariant: (mode) => {},
});

export const ThemeContextProvider = ({ children }) => {
  const [themeVariant, setThemeVariantA] = useState(localStorage.getItem("mode") ?? 'light');
  const [mainColor, setMainColorA] = useState(localStorage.getItem("main") ?? "#475DF4");      // Ultramarine blue
  const [accentColor, setAccentColor] = useState("#E94F37");  // Cinnabar

  // Main colors
  // AA00FF - Vivid violet
  // 475DF4 - Ultramarine blue
  // 0CC5A0 - Caribbean green
  // FDCA40 - Sunglow
  // E94F37 - Cinnabar

  const setMainColor = (color) => {
    setMainColorA(color);
    localStorage.setItem("main", color);
  }

  const setThemeVariant = (theme) => {
    setThemeVariantA(theme);
    localStorage.setItem("mode", theme);
  }

  const theme = useMemo(() => ({
    main: Color(mainColor),
    accent: Color(accentColor),
    mode: themeVariant,

    background: themeVariant === 'light' ? Color("#FFFFFF") : Color("#23252D"), // white       - raisin black
    muted:      themeVariant === 'light' ? Color("#5A657C") : Color("#535E69"), // black coral - black coral
    soft:       themeVariant === 'light' ? Color("#E2E2E2") : Color("#2A2E32"), // platinum    - gunmetal
    surface:    themeVariant === 'light' ? Color("#F5F6F9") : Color("#1A1D24"), // ghost white - raisin black
    text:       themeVariant === 'light' ? Color("#212121") : Color("#F2F2F2"), // eerie black - cultured

    setMainColor,
    setAccentColor,
    setThemeVariant,
  }), [mainColor, accentColor, themeVariant]);

  return (
    <ThemeContext.Provider value={theme}>
      { children }
    </ThemeContext.Provider>
  );
}

export default ThemeContext;
