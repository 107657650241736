/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState } from 'react';
import { useMemo } from 'react';

const AuthContext = createContext({
  token: null,
  forceUpdate: () => {},
  login: (token, persistence) => {},
  logout: () => {},
});

export function AuthContextProvider({ children }) {
  const [shouldUpdate, setShouldUpdate] = useState(false);
  
  const forceUpdate = () => {
    setShouldUpdate(!shouldUpdate);
  }
  
  const token = useMemo(() => {
    let LS_token = localStorage.getItem("uUYrkdStWCTKo");
    let SS_token = sessionStorage.getItem("2ADkoeQEObrpw");

    return LS_token ? LS_token : SS_token;
  }, [shouldUpdate]);

  const login = (token, persistence = false) => {
    if (persistence) localStorage.setItem("uUYrkdStWCTKo", token);   
    sessionStorage.setItem("2ADkoeQEObrpw", token);
    forceUpdate();
  }

  const logout = () => {
    localStorage.removeItem("uUYrkdStWCTKo");
    sessionStorage.removeItem("2ADkoeQEObrpw");
    forceUpdate();
  }

  return (
    <AuthContext.Provider value={{ token, forceUpdate, login, logout }}>
      { children }
    </AuthContext.Provider>
  );
}

export default AuthContext;