import { List, ListItem, ListItemText } from '@material-ui/core';
import React, { useContext, useMemo } from 'react';
import ThemeContext from '../../../../context/ThemeContext';
import StatisticsContext from '../../context';

import { currencyFormat } from '../../../../utils';

function Stats() {
  const { surface, text } = useContext(ThemeContext);
  const { orders } = useContext(StatisticsContext);

  console.log(orders)

  const stats = useMemo(() => ([
    {
      title: 'Pedidos entregues', entry: true,
      amount: orders.filter((item) => item.status === 0).length,
    }, {
      title: 'Ganhos (pedidos entregues)', entry: true,
      amount: currencyFormat(orders.filter((item) => item.status === 0).reduce((acc1, itm1) => acc1 += itm1.orders.reduce((acc, itm) => acc += itm.finalPrice, 0), 0))
    }, {
      title: 'Ganhos previstos', entry: true,
      amount: currencyFormat(orders.filter((item) => [0,1,2,3].some(status => status === item.status)).reduce((acc1, itm1) => acc1 += itm1.orders.reduce((acc, itm) => acc += itm.finalPrice, 0), 0))
    }, {}, {
      title: 'Pedidos cancelados', entry: false,
      amount: orders.filter((item) => item.status === 5).length
    }, {
      title: 'Perdas (pedidos cancelados)', entry: false,
      amount: currencyFormat(orders.filter((item) => item.status === 5).reduce((acc1, itm1) => acc1 += itm1.orders.reduce((acc, itm) => acc += itm.finalPrice, 0), 0))
    }, {}, {
      title: 'Pedidos recusados', entry: false,
      amount: orders.filter((item) => item.status === 4).length
    }, {
      title: 'Perdas (pedidos recusados)', entry: false,
      amount: currencyFormat(orders.filter((item) => item.status === 4).reduce((acc1, itm1) => acc1 += itm1.orders.reduce((acc, itm) => acc += itm.finalPrice, 0), 0))
    }, {
      title: 'Total de perdas', entry: false,
      amount: currencyFormat(orders.filter((item) => [4, 5].some(status => status === item.status)).reduce((acc1, itm1) => acc1 += itm1.orders.reduce((acc, itm) => acc += itm.finalPrice, 0), 0))
    },
  ]), [orders]);

  return (
    <div className="stats" style={{ backgroundColor: surface.hex() }}>
      <List component="div">
        {
          stats[0] ? stats.map(({ amount, description, entry, title }) => (
            <ListItem key={Math.random()} style={{ justifyContent: 'space-between' }}>
              <ListItemText primary={title} style={{ color: text.hex() }} />
              <ListItemText primary={amount} style={{ color: entry ? 'green' : 'red', display: 'flex', justifyContent: 'flex-end' }} />
            </ListItem>
          )) : <></>
        }
      </List>
    </div>
  );
}

export default Stats;