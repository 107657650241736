import React, { useContext } from 'react';
import './animations.css';

import { Scrollbars } from 'react-custom-scrollbars';
import { Container, Surface } from './styles';
import ThemeContext from '../../context/ThemeContext';

function Modal({ width, style = {}, children, visible, onDismiss }) {
  const { surface } = useContext(ThemeContext);

  const handleChildClick = (e) => e.stopPropagation();

  return (
    <Container className={`backdrop ${visible ? "backdrop-open" : "backdrop-hide"}`} onClick={onDismiss}>
      <Surface width={width} onClick={handleChildClick} className={`backdrop-surface`} style={{ ...style, display: 'flex', backgroundColor: surface.hex() }}>
        <Scrollbars>
          { children }
        </Scrollbars>
      </Surface>
    </Container>
  );
}

export default Modal;
