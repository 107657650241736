import React, { useContext, useEffect, useState } from 'react';
import { Button, TextField } from '@material-ui/core';

import { format, addHours } from 'date-fns';
import { dateReverse } from './dateReverse';

import AuthContext from '../../../../../../context/AuthContext';
import ThemeContext from '../../../../../../context/ThemeContext';
import SettingsContext from '../../../../context';
import { useFetch } from '../../../../../../hooks';

function Schedule() {
  const { token } = useContext(AuthContext);
  const { muted, soft, surface, text } = useContext(ThemeContext);
  const { client } = useContext(SettingsContext);
  
  const [businessHours, setBusinessHours] = useState({});

  useEffect(() => {
    if (!["", null, undefined].some(item => item === client.businessHours)) {
      setBusinessHours(JSON.parse(client.businessHours));
    }
  }, [client]);

  const onChange = (e) => {
    const key = e.target.id.split('-')[0];
    const day = e.target.id.split('-')[1];
    const value = format(addHours(e.target.valueAsDate, 3), 'kk:mm');

    setBusinessHours({
      ...businessHours,
      [key] : {
        ...businessHours[key],
        [day] : value
      }
    })
  }

  const handleSave = () => {
    useFetch.post('/a/cli/b', {
      token,
      businessHours
    }, (response) => {
      if (response.code === 'success') {
        alert("Sucesso")
      } else {
        alert("Erro")
      }
    })
  }

  return (
    <div style={{ marginTop: 30 }}>
      Horário de funcionamento <br />
      <div style={{ backgroundColor: surface.hex(), padding: 15, borderRadius: 10, marginTop: 20, display: 'flex', flexDirection: 'column' }}>
        {
          [ "mon", "tue", "wed", "thu", "fri", "sat", "sun", ]
            .map((weekday) => (
              <div key={weekday} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <p style={{ width: 200 }}>{ dateReverse(weekday) }</p>

                <TextField
                  value={businessHours[weekday] ? businessHours[weekday].open : ''}
                  onChange={onChange}
                  style={{ width: 100, marginRight: 40 }}
                  id={`${weekday}-open`}
                  type="time"
                  defaultValue="00:00"
                  InputProps={{ step: 300, style: { color: muted.hex() } }}
                />

                <TextField
                  value={businessHours[weekday] ? businessHours[weekday].close : ''}
                  onChange={onChange}
                  style={{ width: 100, marginRight: 20 }}
                  id={`${weekday}-close`}
                  type="time"
                  defaultValue="00:00"
                  InputProps={{ step: 300, style: { color: muted.hex() } }}
                />
              </div>
            ))
        }
      </div>

      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
        <Button
          variant="contained"
          style={{ backgroundColor: soft.hex(), color: text.hex() }}
          disableElevation
          onClick={handleSave}
        >Salvar alterações</Button>
      </div>
    </div>
  );
}

export default Schedule;