import { createMuiTheme, Switch, ThemeProvider } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';

import ThemeContext from '../../../../../context/ThemeContext';

const ColorPicker = ({ color, name }) => {
  const { main, text, soft, setMainColor } = useContext(ThemeContext);
  const selected = main.hex().toString().toLowerCase() === color;

  return (
    <div onClick={() => setMainColor(color)} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', padding: 5, paddingRight: 10, borderRadius: 20, backgroundColor: selected ? main.hex() : soft.hex(), marginRight: 10 }}>
      <div style={{ backgroundColor: selected ? 'white' : color, borderRadius: 10, height: 20, width: 20, marginRight: 10 }} />
      <span style={{ fontSize: 14, color: selected ? 'white' : text.hex() }}>{ name }</span>
    </div> 
  );
}

function Appearance() {
  const { main, mode, surface, setThemeVariant } = useContext(ThemeContext);
  
  const theme = createMuiTheme({
    palette: {
      primary: { main: main.hex() },
      secondary: { main: main.hex() },
      type: mode
    }
  });
  
  const [themeSwitcher, setThemeSwitcher] = useState(mode === 'dark' ? true : false);
  const handleSwitcherChange = (e) => setThemeSwitcher(e.target.checked);
  useEffect(() => setThemeVariant(themeSwitcher ? 'dark' : 'light'), [setThemeVariant, themeSwitcher]);

  return (
    <div style={{ backgroundColor: surface.hex(), borderRadius: 10, padding: 20 }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>Tema escuro <br /><small>Diminua a quantidade de superfícies claras na interface do sistema.</small></div>
        <ThemeProvider theme={theme}>
          <Switch color="primary" checked={themeSwitcher} onChange={handleSwitcherChange} />
        </ThemeProvider>
      </div>

      <div style={{ marginTop: 30 }}>
        <div>Mudar cor principal</div>
        <div style={{ display: 'flex', marginTop: 10, alignItems: 'center' }}>
          <ColorPicker color="#475df4" name="Ultramarine blue" />
          <ColorPicker color="#aa00ff" name="Vivid violet" />
          <ColorPicker color="#0cc5a0" name="Caribbean Green" />
          <ColorPicker color="#e94f37" name="Cinnabar" />
        </div>
      </div>
    </div>
  );
}

export default Appearance;