import { Button, Checkbox, Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import './index.css';

import { useFetch } from '../../hooks';

import AuthContext from '../../context/AuthContext';

function RightForm() {
  const { login: authenticate } = useContext(AuthContext);

  const [login, setLogin] = useState({ email: '', password: '' });
  const [isError, setIsError] = useState(false);
  const [persistence, setPersistence] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  
  const onChange = (e) => setLogin({ ...login, [e.target.name]: e.target.value });
  
  const handleLogin = () => {
    if (login.email === '' || login.password === '') {
      setIsError(true);
      return;
    }

    setIsLoading(true);

    useFetch.post('/a/auth', {
      login: login.email,
      password: login.password
    }, (response) => {
      if (response.code === 'error') {
        setIsLoading(false);
        setIsError(true);
      } else {
        if (!response[0]) {
          setIsLoading(false);
          return setIsError(true);
        }

        const { clientId } = response[0];
        authenticate(clientId, persistence);
        setIsLoading(false);
      }
    })
  }

  return (
    <div className="login-box">
      <Dialog open={isError} onClose={() => setIsError(false)}>
        <DialogContent>
          <Typography>
            Não foi possível encontrar credenciais para este login.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsError(false)}>Ok</Button>
        </DialogActions>
      </Dialog>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        logo
      </div>

      <div className="login">
        <input onChange={onChange} value={login.email} type="email" name="email" placeholder="Seu endereço de email" />
        <input onKeyDown={(e) => e.code === "Enter" ? handleLogin() : null} onChange={onChange} value={login.password} type="password" name="password" placeholder="Sua palavra passe" />

        <small>Esqueci minha senha</small>
        <button disabled={isLoading} onClick={handleLogin}>Entrar</button>


        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox onClick={() => setPersistence(!persistence)} checked={persistence} />
          <span style={{ cursor: 'pointer', color: 'grey' }} onClick={() => setPersistence(!persistence)}>Mantenha-me conectado</span>
        </div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <small style={{ color: 'grey' }}>
          Todos os direitos reservados <a target="_blank" rel="noopener noreferrer" href="https://dinamicami.com.br/">Dinâmica MI</a> 2020
        </small>
      </div>
    </div>
  );
}

export default RightForm;