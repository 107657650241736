import React, { createContext, useContext, useState } from 'react';
import OptionsList from './OptionList';
import OptionGroupCreate from './OptionGroupCreate';

import ProductsContext from '../../context';

export const ProductOptionsContext = createContext({
  isOptGrpCreate: false,
  showOptGrp: () => {},
  hideOptGrp: () => {},
});

function ProductOptions() {
  const { selected } = useContext(ProductsContext);
  const [isOptGrpCreate, setIsOptGrpCreate] = useState(false);

  const { options } = selected;

  const showOptGrp = () => setIsOptGrpCreate(true);
  const hideOptGrp = () => setIsOptGrpCreate(false);

  return (
    <ProductOptionsContext.Provider value={{ isOptGrpCreate, showOptGrp, hideOptGrp }}>
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <OptionGroupCreate />

        {
          !options[0] ? (
            <span style={{ alignSelf: 'center' }}>Não há opções ainda. <u onClick={showOptGrp} style={{ cursor: 'pointer' }}>Crie uma</u></span>
          ) : <OptionsList />
        }
      </div>
    </ProductOptionsContext.Provider>
  );
}

export default ProductOptions;