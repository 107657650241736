import { Button } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';

import ThemeContext from '../../../../../../../context/ThemeContext';
import ContactContext from '../../context';

function ModalComponent() {
  const { background, muted, soft, text } = useContext(ThemeContext);
  const { selected, select, contacts: contextContacts, setContacts } = useContext(ContactContext);

  const [contact, setContact] = useState(null);

  useEffect(() => setContact(selected), [selected]);

  const setContactContacts = (index, value) => {
    let contacts = contact.contacts;
    contacts[index] = value;
    setContact({ ...contact, contacts });
  }

  const removeIndex = (index) => {
    let contacts = contact.contacts;
    contacts.splice(index, 1);
    setContact({ ...contact, contacts });
  }

  const addNewContact = () => setContact({ ...contact, contacts: [ ...contact.contacts, "" ], });

  const handleRemove = () => {
    let index = contextContacts.findIndex(item => item.title === selected.title);

    if (index > -1) {
      let newContacts = contextContacts;
      newContacts.splice(index, 1);
      setContacts(newContacts)
      select(null);
    }
  }

  const handleSave = () => {
    let index = contextContacts.findIndex(item => item.title === selected.title);

    if (index > -1) {
      let newContacts = contextContacts;
      newContacts[index] = contact;
      setContacts(newContacts)
      select(null);
    } else {
      setContacts([...contextContacts, contact]);
      select(null);
    }
  }

  if (!contact) return <div />

  return (
    <div style={{ color: text.hex(), padding: 50, display: 'flex', flexDirection: 'column' }}>
      <input
        style={{ color: text.hex(), fontSize: '2em', fontWeight: 700, backgroundColor: 'transparent', border: 'none' }}
        type="text"
        value={contact.title}
        onChange={e => setContact({ ...contact, title: e.target.value })}
      />

      <div>
        {
          contact.contacts?.map((item, index) => (
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10, backgroundColor: background.hex(), borderRadius: 10, padding: 15 }}>
              <input
                style={{ color: text.hex(), flex: 1, backgroundColor: 'transparent', border: 'none' }}
                type="text"
                value={contact.contacts[index]}
                onChange={(e) => setContactContacts(index, e.target.value)}
              />
              <Button style={{ color: muted.hex() }} onClick={() => removeIndex(index)}>remover</Button>
            </div>
          ))
        }
        <Button onClick={addNewContact} variant="contained" disableElevation style={{ marginTop: 10, backgroundColor: soft.hex(), color: text.hex() }}>Adicionar</Button>
      </div>

      <div style={{ marginTop: 20, display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          onClick={handleRemove}
          disableElevation
          variant="contained"
          style={{ backgroundColor: soft.hex(), color: text.hex(), marginRight: 10 }}
        >Remover</Button>
        
        <Button
          onClick={handleSave}
          disableElevation
          variant="contained"
          style={{ backgroundColor: soft.hex(), color: text.hex() }}
        >Salvar</Button>
      </div>
    </div>
  );
}

export default ModalComponent;