import React, { useContext } from 'react';
import { OrderItem as Container, Text } from './styles';

import ProductContext from '../../context';

const formatCurrency = (value) => {
  return value === 0 ? "Sem valor" : "R$ " + parseFloat(value).toFixed(2).replace('.', ',')
}

function ProductItem({ product }) {
  const { select, setIsModalActive } = useContext(ProductContext);
  const { category, name, price, salePrice, } = product;

  const handleSelect = () => {
    select(product);
    setIsModalActive(true);
  }

  return (
    <Container>
      <div style={{ display: 'flex', flex: 1, alignSelf: 'stretch' }} onClick={handleSelect}>
        <div style={{ flex: 1, padding: 15 }}><Text>{name}</Text></div>
        <div style={{ flex: 1, padding: 15 }}><Text>{category}</Text></div>
        <div style={{ flex: 1, padding: 15 }}><Text color={salePrice === 0 ? 'grey' : null}>{formatCurrency(salePrice)}</Text></div>
        <div style={{ flex: 1, padding: 15 }}><Text color={price === 0 ? 'grey' : null}>{formatCurrency(price)}</Text></div>
      </div>
    </Container>
  );
}

export default ProductItem;