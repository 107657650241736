import React, { useContext } from 'react';

import DrawerComponent from './DrawerComponent';
import Switcher from './Switcher';
import { SettingsContextProvider } from './context';

import ThemeContext from '../../context/ThemeContext';

function Settings () {
  const { background, text } = useContext(ThemeContext);

  return (
    <div style={{ backgroundColor: background.hex(), height: '90vh', display: 'flex', color: text.hex() }}>
      <SettingsContextProvider>
        <DrawerComponent />
        <Switcher />
      </SettingsContextProvider>
    </div>
  );
}

export default Settings;