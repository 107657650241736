import styled from 'styled-components';

export const TextInput = styled.input`
  background-color: transparent;
  border: none;
  margin-left: 10px;
  height: 40px;
  width: 350px;

  font-size: 17px;
`;