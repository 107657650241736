import React, { createContext, useContext, useMemo, useState } from 'react';
import { addDays, subDays, subWeeks, subMonths, subYears } from 'date-fns';

import SocketContext from '../../context/SocketContext';

const StatisticsContext = createContext({
  isLoading: true,
  orders: [],
  payMethods: [],

  interval: 0,
  setInterval: (number) => {}
});

export const StatisticsContextProvider = ({ children }) => {
  const { isLoading, orders: orderList, paymentMethods: payMethods } = useContext(SocketContext);

  const [interval, setInterval] = useState(0);

  // [1]: Hoje, [2]: Semana, [3]: Mês, [4]: Ano, [0]: Tudo
  const endDate =  addDays(new Date().setHours(0,0,0,0), 1);

  const orders = useMemo(() => {
    let returning = [];

    switch (interval) {
      case 1:
        returning = orderList.filter(({ createdAt }) => parseInt(createdAt) > subDays(endDate, 1) && parseInt(createdAt) < endDate);
        break;

      case 2:
        returning = orderList.filter(({ createdAt }) => parseInt(createdAt) > subWeeks(endDate, 1) && parseInt(createdAt) < endDate);
        break;

      case 3:
        returning = orderList.filter(({ createdAt }) => parseInt(createdAt) > subMonths(endDate, 1) && parseInt(createdAt) < endDate);
        break;

      case 4:
        returning = orderList.filter(({ createdAt }) => parseInt(createdAt) > subYears(endDate, 1) && parseInt(createdAt) < endDate);
        break;
      
      default: 
        returning = orderList;
        break;
    }

    return returning;
  }, [interval, orderList, endDate]);

  return (
    <StatisticsContext.Provider value={{ isLoading, orders, payMethods, interval, setInterval }}>
      { children }
    </StatisticsContext.Provider>
  );
}

export default StatisticsContext;
