import React, { useContext } from 'react';
import ThemeContext from '../../../../context/ThemeContext';

function Header() {
  const { text, soft } = useContext(ThemeContext);
  
  return (
    <div style={{ padding: 15, backgroundColor: soft, display: 'flex', marginBottom: 15, borderRadius: 10 }}>
      <div style={{ width: 130, marginRight: 20 }}>
        <span style={{ color: text}}>Status</span>
      </div>
      <div style={{ display: 'flex', flex: 1, alignSelf: 'stretch' }}>
        <div style={{ flex: 1, paddingRight: 10 }}><span style={{ color: text }}>Cliente</span></div>
        <div style={{ flex: 1, paddingRight: 10 }}><span style={{ color: text }}>Qtd. items</span></div>
        <div style={{ flex: 1, paddingRight: 10 }}><span style={{ color: text }}>Pagamento</span></div>
        <div style={{ flex: 1, paddingRight: 10 }}><span style={{ color: text }}>Horário</span></div>
      </div>
    </div>
  );
}

export default Header;
