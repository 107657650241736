import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Portal } from '@material-ui/core';
import React, { useContext, useState } from 'react';

import Modal from '../../../../../../components/Modal';
import ModalComponent from './ModalComponent';
import ContactItem from './ContactItem';

import AuthContext from '../../../../../../context/AuthContext';
import ThemeContext from '../../../../../../context/ThemeContext';
import ContactContext from '../context';
import { useFetch } from '../../../../../../hooks';

function ContactEditor() {
  const { token } = useContext(AuthContext);
  const { soft, surface, text } = useContext(ThemeContext);
  const { contacts, select, selected } = useContext(ContactContext);

  const [isSuccess, setIsSuccess] = useState(false)

  const handleSave = () => {
    useFetch.post("/a/cli/a", {
      token,
      andresses: contacts,
    }, () => {
      setIsSuccess(true)
    })
  }

  return (
    <div>
      <Portal>
        <Modal visible={Boolean(selected)} onDismiss={() => select(null)}>
          <ModalComponent />
        </Modal>
      </Portal>

      <Portal>
        <Dialog open={isSuccess} onClose={() => setIsSuccess(false)}>
          <DialogTitle style={{ backgroundColor: surface.hex(), color: text.hex() }}>
            Sucesso
          </DialogTitle>
          <DialogContent style={{ backgroundColor: surface.hex(), color: text.hex() }}>
            Os dados de contato foram salvos com sucesso!
          </DialogContent>
          <DialogActions style={{ backgroundColor: surface.hex() }}>
            <Button style={{ backgroundColor: soft.hex(), color: text.hex() }} onClick={() => setIsSuccess(false)}>Ok</Button>
          </DialogActions>
        </Dialog>
      </Portal>

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          Seletor de Contato<br />
          <small>Os contatos registrados aqui ficarão disponíveis dentro do aplicativo em Opções &gt; Contato.</small>
        </div>

        <Button
          onClick={() => select({ title: "Novo Item", contacts: [] })}
          style={{ backgroundColor: soft.hex(), color: text.hex() }}
          variant="contained" disableElevation
        >Adicionar</Button>
      </div>

      <div style={{ backgroundColor: surface.hex(), padding: 15, borderRadius: 10, marginTop: 20 }}>
        {
          !contacts[0] ? (
            <small>Não há nenhum contato</small>
          ) : contacts.map((item) => (
            <ContactItem item={item} key={item.title} />
          ))
        }
      </div>

      <div style={{ marginTop: 20, display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          onClick={handleSave}
          style={{ backgroundColor: soft.hex(), color: text.hex() }}
          variant="contained" disableElevation
        >Salvar alterações</Button>
      </div>
    </div>
  );
}

export default ContactEditor;
