import React, { createContext, useContext, useState } from 'react';

import Modal from '../../../components/Modal';

import ThemeContext from '../../../context/ThemeContext';
import ProductsContext from '../context';

import ModalHeader from './ModalHeader';
import ProductOptions from './ProductOptions';
import ProductActions from './ProductActions';

export const ProductActionsContext = createContext({
  transfer: false, update: false, delete: false, image: false, changeAction: (key, value) => {},
})

function ModalComponent() {
  const { text } = useContext(ThemeContext);
  const { isModalActive, select, selected, setIsModalActive } = useContext(ProductsContext);

  const [controller, setController] = useState({ transfer: false, update: false, delete: false });
  const changeAction = (key, value) => setController({...controller, [key]: value });

  const closeModal = () => {
    setIsModalActive(false);
    select(null);
  }

  return (
    <Modal
      width="80vw"
      style={{ color: text }}
      visible={isModalActive}
      onDismiss={closeModal}
    >
      <ProductActionsContext.Provider value={{...controller, changeAction }}>
      {
        !selected ? <></> : (
          <div style={{ flex: 1, flexDirection: 'column' }}>
            <ModalHeader />
            <ProductActions />
            
            <div style={{ flex: 1, padding: 50, paddingTop: 0, marginTop: -20, cursor: 'default' }}>
              <ProductOptions />
            </div>
          </div>
        )
      }
      </ProductActionsContext.Provider>
    </Modal>
  );
}

export default ModalComponent;