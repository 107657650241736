import React, { useContext } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';

import Andress from './pages/Andress';
import Appearance from './pages/Appearance';
import General from './pages/General';
import Security from './pages/Security';

import ThemeContext from '../../../context/ThemeContext';
import SettingsContext from '../context';

function Switcher() {
  const { main, mode, surface, text } = useContext(ThemeContext);
  const { actualPage, isLoading } = useContext(SettingsContext);

  const theme = createMuiTheme({
    palette: {
      background: { default: surface.hex(), paper: surface.hex(), contrastText: text.hex() },
      secondary: { main: main.hex(), contrastText: text.hex() },
      type: mode
    }
  });

  function getPage () {
    switch (actualPage) {
      default: 
        return <General />;
      case 1:
        return <Andress />;
      case 2:
        return <Security />;
      case 3:
        return <Appearance />;
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Scrollbars>
        <div style={{ flex: 1, marginTop: 40, marginRight: 40, width: '70vw' }}>
          { isLoading ? (
            <div>
              <span>Carregando...</span>
            </div>
          ) : getPage() }
        </div>
      </Scrollbars>
    </ThemeProvider>
  )
}

export default Switcher;
