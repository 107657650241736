import React, { useContext } from 'react';
import Modal from '../../../components/Modal';

import Badge from './BadgeToClipboard';
import ModalHeader from './ModalHeader';
import OrderDetails from './OrderDetails';
import UserDetails from './UserDetails';
import ProductList from './ProductList';
import Report from './Report';

import ThemeContext from '../../../context/ThemeContext';
import OrdersContext from '../context';

function ModalComponent() {
  const { text } = useContext(ThemeContext);
  const { isModalActive, select, selected, setIsModalActive } = useContext(OrdersContext);

  const closeModal = () => {
    setIsModalActive(false);
    select(null);
  }

  return (
    <Modal
      style={{ color: text }}
      visible={isModalActive}
      onDismiss={closeModal}
    >
      {
        !selected ? <></> : (
          <div style={{ padding: 50, paddingTop: 30, cursor: 'default', display: 'flex', flexDirection: 'column' }}>
            <ModalHeader />

            <div style={{ display: 'flex' }}>
              <h1 style={{ alignSelf: 'baseline', margin: 0, marginTop: 10, fontSize: 50 }}>Detalhes do pedido</h1>
              <Badge identifier={selected.identifier} />
            </div>

            <UserDetails />
            <OrderDetails />
            <ProductList />
            <Report />
          </div>
        )
      }
    </Modal>
  );
}

export default ModalComponent;