import styled from 'styled-components';

export const Container = styled.div`
  background-color: white;
  max-width: 95%;
  min-width: 400px;
  outline: none;
  padding: 20px;
  width: 40%;
  display: flex;
`;
