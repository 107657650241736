import React, { useContext, useState } from 'react';
import './index.css';

import { Icon } from '@mdi/react';
import { mdiMinusCircleOutline } from '@mdi/js';
import { createMuiTheme, IconButton, ThemeProvider } from '@material-ui/core';

import OptionItemUpdate from './OptionItemUpdate';

import ThemeContext from '../../../../../../../context/ThemeContext';
import ProductContext from '../../../../../context';

import { useFetch } from '../../../../../../../hooks';

function currencyFormat (price) {
  return price === 0 ? "Gratuito" : "R$ " + parseFloat(price).toFixed(2).replace('.', ',');
}

function OptionItem({ optionGroupId, optionItem }) {
  const { main, mode, surface } = useContext(ThemeContext);
  const { findProducts, select, selected } = useContext(ProductContext);

  const [isUpdate, setIsUpdate] = useState(false); 
  const [deleted, setDeleted] = useState(false);

  const theme = createMuiTheme({
    palette: {
      background: { default: surface.hex(), paper: surface.hex() },
      secondary: { main: main.hex() },
      type: mode
    }
  });

  const handleDelete = (e) => {
    e.stopPropagation();

    useFetch.delete('/a/opt/itm/' + optionItem.optionItemId, (response) => {
      if (response.code === 'success') {
        setDeleted(true);

        let product = selected;

        let optGroupIndex = product.options.findIndex(item => item.optionGroupId === optionGroupId);
        product.options[optGroupIndex].options = product.options[optGroupIndex].options.filter(item => item.optionItemId !== optionItem.optionItemId);

        select(product);
        findProducts();
      } else {
        alert("Algo de errado aconteceu durante a exclusão da opção.");
      }
    })
  }

  const dismissModal = (e) => {
    if (e !== undefined) {
      e.stopPropagation();
    }
    setIsUpdate(false)
  }

  return (
    <ThemeProvider theme={theme}>
      <div className={`optionItem ${deleted ? "deleted" : ""}`} onClick={() => setIsUpdate(true)}>
        <OptionItemUpdate optionGroupId={optionGroupId} optionItem={optionItem} visible={isUpdate} onDismiss={dismissModal} />

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <IconButton onClick={handleDelete} size="small" style={{ marginRight: 15 }}>
            <Icon path={mdiMinusCircleOutline} size={1} title={`Remover opção: ${optionItem.name}`} color={'red'} />
          </IconButton>
          <span>{ optionItem.name }</span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span>{ currencyFormat(optionItem.addValue) }</span>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default OptionItem;
