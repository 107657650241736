import React, { useContext, useMemo, useState } from 'react';
import { Button, createMuiTheme, FormControl, InputLabel, MenuItem, Modal, Select, ThemeProvider } from '@material-ui/core';
import { Container } from './styles';

import ThemeContext from '../../../../../context/ThemeContext';
import ProductContext from '../../../context';
import { ProductActionsContext } from '../../index';

import { useFetch } from '../../../../../hooks';

function ProductTransfer() {
  const { main, mode, soft, surface, text } = useContext(ThemeContext);
  const { findProducts, categories, select, selected } = useContext(ProductContext);
  const { transfer: visible, changeAction } = useContext(ProductActionsContext);
  const onDismiss = () => changeAction("transfer", false);

  const theme = createMuiTheme({
    palette: {
      background: { default: surface.hex(), paper: surface.hex() },
      secondary: { main: main.hex() },
      type: mode
    }
  });

  const [selectedCategory, setSelectedCategory] = useState("");
  const [isError, setIsError] = useState(false);

  const categoryId = useMemo(() => {
    let category = categories.find(item => item.name === selectedCategory);
    return category ? category.categoryId : 0;
  }, [categories, selectedCategory]);

  const handleTransfer = () => {
    if (selected === "") {
      setIsError(true);
      return;
    }

    useFetch.post('/a/product/transfer', {
      categoryId,
      productId: selected.productId,
    }, (response) => {
      if (response.code === 'success') {
        select({...selected, category: selectedCategory });
        findProducts();
        onDismiss();
      } else {
        alert("Ocorreu um erro durante a transferência.");
      }
    })
  }


  return (
    <ThemeProvider theme={theme}>
      <Modal
        open={visible}
        onClose={onDismiss}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Container style={{ backgroundColor: surface.hex() }}>
          <FormControl>
            <InputLabel id="category-select-label">Transferir para categoria</InputLabel>
            <Select
              variant="filled"
              style={{ width: 400 }}
              labelId="category-select-label"
              value={selectedCategory}
              onChange={e => setSelectedCategory(e.target.value)}
              error={isError}
            >
              <MenuItem value={0}>Selecione</MenuItem>
              {
                categories.map((category) => (
                  <MenuItem value={category.name}>{ category.name }</MenuItem>
                ))
              }
            </Select>
          </FormControl>

          <Button
            onClick={handleTransfer}
            style={{ backgroundColor: soft.hex(), color: text.hex(), marginTop: 20, alignSelf: 'flex-end' }}
            variant="contained"
            disableElevation
          >
            Transferir
          </Button>
        </Container>
      </Modal>
    </ThemeProvider>
  );
}

export default ProductTransfer;