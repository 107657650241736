import React, { createContext, useContext, useEffect, useState } from 'react';
import { useFetch } from '../hooks';
import AuthContext from './AuthContext';
import Notification from 'react-web-notification';
import io from 'socket.io-client';
import { format } from 'date-fns';

const SocketContext = createContext({
  isLoading: true,
  badge: 0,
  clearBadge: () => {},
  findOrders: () => {},
  orders: [],
  paymentMethods: []
});

export const SocketContextProvider = ({ children }) => {
  const { token } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [badge, setBadge] = useState(0);

  const [notification, setNotification] = useState({
    visible: false,
    ignore: false,
    count: 0
  });

  const handleNotSupported = () => setNotification({ ...notification, ignore: true });
  const handlePermissionGranted = () => setNotification({ ...notification, ignore: false });
  const handlePermissionDenied = () => setNotification({ ...notification, ignore: true });

  if (token) {
    const socket = io.connect("http://dmihost.com.br:21084/", { query: { token } });

    socket.on("movimentation", (badgeCount) => {
      fetchAPI(token);
      setNotification({ ...notification, visible: true, count: badgeCount });
      setBadge(badge + badgeCount);
    });
  }

  const clearBadge = () => {
    setBadge(0);
  }

  useEffect(() => {
    if (token) fetchAPI(token);
  }, [token]);

  const findOrders = () => fetchAPI(token);

  const fetchAPI = (key) => {
    useFetch.get('/a/orders/p/' + key, (response) => {
      if (response.code) alert("Algo de errado aconteceu durante a busca de pedidos, por favor, contate o desenvolvedor");
      else {
        setOrders(response.orders.sort((a, b) => parseInt(a.createdAt) > parseInt(b.createdAt) ? -1 : 1 ));
        setPaymentMethods(response.paymentMethods);
      }
      setIsLoading(false);
    });
  }
  
  return (
    <SocketContext.Provider value={{ isLoading, badge, clearBadge, findOrders, orders, paymentMethods }}>
      { notification.visible ? <Notification
        ignore={notification.ignore}
        notSupported={(handleNotSupported)}
        onPermissionGranted={handlePermissionGranted}
        onPermissionDenied={handlePermissionDenied}
        onClose={() => setNotification({ ...notification, visible: false })}
        timeout={5000}
        title={"Delivery"}
        options={{
          body: `${notification.count === 1 ? "1 novo pedido" : `${notification.count} novos pedidos`}\n${format(new Date(), "kk'H'mm")}`,
          icon: 'https://assets.website-files.com/5361015192ee19db6f000b48/5e27571505a1c3a2caba300d_%C3%ADconeatendimento.png',
          lang: "pt-br",
        }}
      /> : <></>}
      { children }
    </SocketContext.Provider>
  )
}

export default SocketContext;
