import styled from 'styled-components';

export const Container = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 0px; left: 0px; right: 0px; bottom: 0px;
  z-index: 1;
`;

export const Surface = styled.div`
  width: ${props => props.width ?? "60vw"};
`;