import React, { useContext } from 'react';
import { TextInput } from './styles';

import { Icon } from '@mdi/react';
import { mdiMagnify } from '@mdi/js';

import ThemeContext from '../../../../context/ThemeContext';
import OrdersContext from '../../context';
import { Button } from '@material-ui/core';

function Search({ createProduct }) {
  const { text, soft } = useContext(ThemeContext);
  const { search, setSearch } = useContext(OrdersContext);

  const handleSearchChange = (e) => setSearch(e.target.value);

  return (
    <div style={{ alignItems: 'center', display: 'flex', padding: 15, marginBottom: 15, justifyContent: 'space-between' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Icon path={mdiMagnify} size={1} color={text.hex()} />
        <TextInput
          value={search}
          onChange={handleSearchChange}
          placeholder="Pesquise por produto ou categoria"
          style={{ color: text }}
        />
      </div>
      <Button
        style={{ backgroundColor: soft.hex(), color: text.hex() }}
        variant="contained"
        disableElevation
        onClick={createProduct}
      >Criar produto</Button>
    </div>
  );
}

export default Search;