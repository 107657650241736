/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import Authentication from './authentication';

import Orders from './pages/Orders';
import Products from './pages/Products';
import Statistics from './pages/Statistics';
import Settings from './pages/Settings';

import Header from './components/Header';

import AuthContext from './context/AuthContext';

export default function Routes () {
  const { token } = useContext(AuthContext);

  return (
    <BrowserRouter>
      {
        token ? (
          <Header />
        ) : <></>
      }
      <Switch>
        {
          token ? (
            <>
              <Route exact path="/" component={Orders} />
              <Route path="/products" component={Products} />
              <Route path="/statistics" component={Statistics} />
              <Route path="/settings" component={Settings} />
            </>
          ) : (
            <>
              <Route path="/" component={Authentication} />
              <Redirect to="/" />
            </>
          )
        }
      </Switch>
    </BrowserRouter>
  );
}
