import React, { useContext, useState } from 'react';
import { Button, createMuiTheme, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Modal, Switch, TextField, ThemeProvider } from '@material-ui/core';

import ThemeContext from '../../../../../context/ThemeContext';
import ProductContext from '../../../context';
import { ProductOptionsContext } from '../index';

import { useFetch } from '../../../../../hooks';

function OptionGroupCreate() {
  const { isOptGrpCreate: visible, hideOptGrp: onDismiss } = useContext(ProductOptionsContext);
  const { main, mode, surface, text } = useContext(ThemeContext);
  const { findProducts, select, selected } = useContext(ProductContext);

  const { productId } = selected;

  const [title, setTitle] = useState("");
  const [maxItems, setMaxItems] = useState("");
  const [required, setRequired] = useState(false);
  const [unique, setUnique] = useState(false);

  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState("");
  
  const theme = createMuiTheme({
    palette: {
      secondary: { main: main.hex() },
      type: mode
    }
  });

  const showError = (message = "Algo de errado aconteceu.") => {
    setMessage(message);
    setIsError(true);
  }

  const handleCreate = () => {
    if (title === '') {
      showError("Preencha o campo de título");
      return;
    }

    useFetch.post('/a/opt/grp/create', {
      productId,
      title,
      required,
      unique,
      maxItems: unique ? 0 : parseInt(maxItems),
    }, (response) => {
      if (response.code === 'success') {
        const { id } = response;

        let newProduct = selected
        newProduct.options.push({ optionGroupId: id, unique, maxItems, title, required, options: [] });

        select(newProduct);
        findProducts();

        onDismiss();
      } else {
        showError();
      }
    })
  }

  return (
    <ThemeProvider theme={theme}>
      <Modal open={visible} onClose={onDismiss} style={{ outline: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ outline: 'none', backgroundColor: surface.hex(), color: text.hex(), padding: 20 }}>

          <Dialog open={isError} onClose={() => setIsError(false)}>
            <DialogTitle>Erro</DialogTitle>
            <DialogContent>{ message }</DialogContent>
            <DialogActions>
              <Button onClick={() => setIsError(false)}>
                Ok
              </Button>
            </DialogActions>
          </Dialog>

          {/* content */}

          <TextField
            style={{ width: 300 }}
            label="Novo grupo de opções"
            placeholder="Ex.: Acompanhamentos"
            value={title}
            onChange={e => setTitle(e.target.value)}
            color="secondary"
          />

          <TextField
            style={{ marginLeft: 10, width: 100 }}
            label="Qtd. Máx"
            type="number"
            placeholder="Quantidade máxima"
            value={maxItems}
            onChange={e => setMaxItems(e.target.value)}
            color="secondary"
          />

          

          <div style={{ marginTop: 20, display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex' }}>
              <FormControl style={{ marginLeft: 20 }}>
                <Switch
                  checked={required}
                  onChange={e => setRequired(e.target.checked)}
                />
                <FormHelperText>Obrigatório</FormHelperText>
              </FormControl>

              <FormControl style={{ marginLeft: 20, display: 'flex', alignItems: 'center'  }}>
                <Switch
                  checked={unique}
                  onChange={e => setUnique(e.target.checked)}
                />
                <FormHelperText>Único</FormHelperText>
              </FormControl>
            </div>
            <div>
              <Button style={{ marginRight: 10 }} onClick={onDismiss}>Cancelar</Button>
              <Button onClick={handleCreate} variant="contained" disableElevation>Ok</Button>
            </div>
          </div>
        </div>
      </Modal>
    </ThemeProvider>
  );
}

export default OptionGroupCreate;