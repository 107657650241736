import { Button, createMuiTheme, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Modal, Switch, TextField, ThemeProvider } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { useFetch } from '../../../../../../../hooks';

import ThemeContext from '../../../../../../../context/ThemeContext';
import ProductContext from '../../../../../context'; 
import { OptionGroupContext } from '../index';

import { currencyFormat } from '../../../../../../../utils';

function OptionItemCreate({ optionGroup }) {
  const { main, mode, soft, surface, text } = useContext(ThemeContext);
  const { findProducts, select, selected: product } = useContext(ProductContext);
  const { create: visible, changeModal } = useContext(OptionGroupContext);
  const onDismiss = () => changeModal("create", false);

  const theme = createMuiTheme({
    palette: {
      secondary: { main: main.hex() },
      type: mode
    }
  });

  const [title, setTitle] = useState("");
  const [price, setPrice] = useState("");
  const [free, setFree] = useState(false);

  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState("");

  const showError = (message = "Algo de errado aconteceu.") => {
    setMessage(message);
    setIsError(true);
  }

  const handleCreate = () => {
    let priceValue = 0;

    if (title.trim() === "") {
      showError("Insira um título");
    }

    if (!free) {
      if (price.trim() === "") {
        showError("Valor inválido");
        return;
      }
      try {
        priceValue = parseFloat(price.replace('R$ ', '').replace(',', '.'));
      } catch (e) {
        showError("Valor inválido");
        return;
      }
    }

    useFetch.post('/a/opt/itm/create', {
      optionGroupId: optionGroup.optionGroupId,
      title,
      price: priceValue,
    }, (response) => {
      if (response.code === 'success') {
        const { id: optionItemId } = response;
        const { options } = product;

        let index = options.findIndex(item => item.optionGroupId === optionGroup.optionGroupId);
        options[index] = {
          ...optionGroup,
          options: [
            ...optionGroup.options, {
              optionItemId,
              name: title,
              addValue: priceValue
            },
          ],
        };

        select({...product, options });
        findProducts();
        onDismiss();
      } else {
        showError();
      }
    });
  }
  
  return (
    <ThemeProvider theme={theme}>
      <Modal open={visible} onClose={onDismiss} style={{ outline: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ outline: 'none', backgroundColor: surface.hex(), padding: 20 }}>
          <Dialog open={isError} onClose={() => setIsError(false)}>
            <DialogTitle>Erro</DialogTitle>
            <DialogContent>{ message }</DialogContent>
            <DialogActions>
              <Button onClick={() => setIsError(false)}>
                Ok
              </Button>
            </DialogActions>
          </Dialog>

          {/* content */}
          <div>
            <TextField
              style={{ width: 300 }}
              label={`Novo item em ${optionGroup.title}`}
              placeholder="Ex.: Batata frita"
              value={title}
              onChange={e => setTitle(e.target.value)}
              color="secondary"
            />
          </div>

          <div style={{ display: 'flex', marginTop: 15 }}>
            <TextField
              disabled={free}
              style={{ flex: 1 }}
              label="Valor"
              placeholder="Apenas números"
              value={price}
              onChange={e => setPrice(currencyFormat(e.target.value))}
              color="secondary"
            />

            <FormControl style={{ marginLeft: 20 }}>
              <Switch
                checked={free}
                onChange={e => setFree(e.target.checked)}
              />
              <FormHelperText>Gratuito</FormHelperText>
            </FormControl>
          </div>
          <div style={{ marginTop: 20, display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
            <Button onClick={onDismiss}>Cancelar</Button>
            <Button style={{ marginLeft: 10, backgroundColor: soft.hex(), color: text.hex() }} onClick={handleCreate} variant="contained" disableElevation>Criar</Button>
          </div>
        </div>
      </Modal>
    </ThemeProvider>
  );
}

export default OptionItemCreate;