import Color from 'color';

const treatment = (hex) => Color(hex).hex();

export const colors = [
  treatment("#AA00FF"), 
  treatment("#8739ED"), 
  treatment("#475DF4"), 
  treatment("#4981EB"), 
  treatment("#0CC5A0"),
  treatment("#97E300"), 
  treatment("#CDEC42"), 
  treatment("#FDCA40"), 
  treatment("#F67D5B"), 
  treatment("#E94F37")
]