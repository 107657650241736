import React, { useContext, useState } from 'react';
import { Button, createMuiTheme, Dialog, DialogActions, DialogContent, DialogTitle, Modal, TextField, ThemeProvider } from '@material-ui/core';

import ThemeContext from '../../../../../context/ThemeContext';
import ProductContext from '../../../context';
import { ProductActionsContext } from '../../index';

import { useFetch } from '../../../../../hooks';

import { currencyFormat } from '../../../../../utils';

function ProductUpdate() {
  const { main, mode, soft, surface, text } = useContext(ThemeContext);
  const { select, selected: product, findProducts } = useContext(ProductContext);
  const { update: visible, changeAction } = useContext(ProductActionsContext);
  const onDismiss = () => changeAction("update", false);

  const theme = createMuiTheme({
    palette: {
      background: { default: surface.hex(), paper: surface.hex() },
      secondary: { main: main.hex() },
      type: mode
    }
  });

  const [title, setTitle] = useState(product.name);
  const [description, setDescription] = useState(product.details);
  const [price, setPrice] = useState(currencyFormat(product.price));
  const [sale, setSale] = useState(currencyFormat(product.salePrice));

  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState("");

  const [titleError, setTitleError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [priceError, setPriceError] = useState(false);
  const [saleError, setSaleError] = useState(false);
  
  const showError = (message = "Algo de errado aconteceu.") => {
    setMessage(message);
    setIsError(true);
  }

  const handleCreate = () => {
    let hasError = false;

    if (title === '') {
      hasError = true;
      setTitleError(true);
    } else {
      setTitleError(false);
    }
    
    if (description === '') {
      hasError = true;
      setDescriptionError(true);
    } else {
      setDescriptionError(false);
    }
    
    if (price === '') {
      hasError = true;
      setPriceError(true); 
    } else {
      setPriceError(false); 
    }
    
    if (sale === '') {
      hasError = true;
      setSaleError(true); 
    } else {
      setSaleError(false); 
    }

    if (hasError) {
      showError("Existem campos não preenchidos");
      return;
    }
    //
    let priceValue = 0;
    let promoValue = 0;

    try {
      priceValue = parseFloat(price.replace('R$ ', '').replace(',', '.'));
    } catch (e) {
      showError("Valor inválido");
      return;
    }

    try {
      promoValue = parseFloat(sale.replace('R$ ', '').replace(',', '.'));
    } catch (e) {
      showError("Valor promocional inválido");
      return;
    }

    // 
    const data = new FormData();

    data.append("productId", product.productId);
    data.append("title", title);
    data.append("description", description);
    data.append("price", priceValue);
    data.append("sale", promoValue);
    data.append("update_image", '0');


    useFetch.postFormData('/a/product/update', data, (response) => {
      if (response.code === 'success') {
        select({
          ...product,
          productId: product.productId,
          name: title,
          image: '',
          details: description,
          price: priceValue,
          salePrice: promoValue,
        });
        findProducts();
        onDismiss();
      } else {
        showError();
      }
    });

  }

  return (
    <ThemeProvider theme={theme}>
      <Modal
        open={visible}
        onClose={onDismiss}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <div style={{ backgroundColor: surface.hex(), padding: 20 }} className="container">
          <Dialog open={isError} onClose={() => setIsError(false)}>
            <DialogTitle>Erro</DialogTitle>
            <DialogContent>{ message }</DialogContent>
            <DialogActions>
              <Button onClick={() => setIsError(false)}>
                Ok
              </Button>
            </DialogActions>
          </Dialog>

          <h3 style={{ color: text.hex() }}>Editando em { product.name }</h3>
          <div style={{ display: 'flex', flexDirection: 'column', }}>
            <TextField
              value={title}
              onChange={e => setTitle(e.target.value)}
              variant="filled"
              label="Nome"
              placeholder="Insira o nome do produto"
              error={titleError}
            />
            <TextField
              value={description}
              onChange={e => setDescription(e.target.value)}
              variant="filled"
              label="Descrição"
              placeholder="Insira uma descrição para o produto"
              style={{ marginTop: 20 }}
              multiline
              error={descriptionError}
            />
            <div style={{ marginTop: 20, display: 'flex' }}>
              <TextField
                value={price}
                onChange={e => setPrice(currencyFormat(e.target.value))}
                variant="filled"
                label="Valor"
                placeholder="R$ 0,00"
                error={priceError}
              />
              <TextField
                value={sale}
                onChange={e => setSale(currencyFormat(e.target.value))}
                variant="filled"
                label="Valor Promo"
                placeholder="R$ 0,00"
                style={{ marginLeft: 20 }}
                error={saleError}
              />
            </div>
          </div>

          <div style={{ marginTop: 20 }}>
            <Button onClick={onDismiss}>Cancelar</Button>
            <Button style={{ backgroundColor: soft.hex(), color: text.hex() }} onClick={handleCreate} variant="contained" disableElevation>Atualizar</Button>
          </div>
        </div>
      </Modal>
    </ThemeProvider>
  );
}

export default ProductUpdate;