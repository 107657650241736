import React, { useContext } from 'react';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';

import ThemeContext from '../../../../../context/ThemeContext';
import OrdersContext from '../../../context';

import { useFetch } from '../../../../../hooks';

function Popover({ anchor, open, onClose }) {
  const { soft, text } = useContext(ThemeContext);
  const { select, selected, findOrders } = useContext(OrdersContext);

  const theme = createMuiTheme({ overrides: { MuiMenu: { paper: { backgroundColor: soft.hex() }}, MuiMenuItem: { root: { backgroundColor: soft.hex(), color: text.hex() }} } });

  if (!selected) return <></>;
  const { status, orderId } = selected;

  const setOrderStatus = (newstatus) => {
    useFetch.post('/a/orders/status', {
      orderId,
      status: newstatus,
    }, (response) => {
      if (response.code === 'success') {
        setTimeout(() => {
          findOrders();
          select({ ...selected, status: newstatus });
        }, 1000);
      }
    })
  }

  return (
    <MuiThemeProvider theme={theme}>
      <Menu open={open} anchorEl={anchor} onClose={onClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} transformOrigin={{ vertical: 'top', horizontal: 'right' }} >
        {
          status === 1 ? (
            <>
              <MenuItem onClick={() => setOrderStatus(2)}>Confirmar preparo</MenuItem>
              <MenuItem onClick={() => setOrderStatus(4)}>Recusar</MenuItem>
            </>
          ) : status === 2 ? (
            <MenuItem onClick={() => setOrderStatus(3)}>Confirmar saída para entrega</MenuItem>
          ) : status === 3 ? (
            <MenuItem onClick={() => setOrderStatus(0)}>Confirmar entrega</MenuItem>
          ) : status === 4 ? (
            <MenuItem disabled>Este pedido foi recusado</MenuItem>
          ) : status === 5 ? (
            <MenuItem disabled>Cancelado pelo usuário</MenuItem>
          ) : (
            <MenuItem disabled>O pedido já foi entregue</MenuItem>
          )
        }
      </Menu>
    </MuiThemeProvider>
  );
}

export default Popover;