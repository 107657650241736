import React from 'react';
import { Router } from 'react-router-dom';
import Routes from './routes';
import history from './services/history';

import Context from './context';
import { Grommet } from 'grommet';
import { theme } from './config/grommet';

function App() {
  return (
    <Grommet theme={theme}>
      <Context>
        <Router history={history}>
          <Routes />
        </Router>
      </Context>
    </Grommet>
  );
}

export default App;
