import styled from 'styled-components';

export const Badge = styled.small`
  padding: 4px 10px;
  border-radius: 100px;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  margin-left: 10px;
  font-weight: 300;
  max-width: 200px;
`;