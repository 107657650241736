import React, { useContext } from 'react';

import ThemeContext from '../../../../context/ThemeContext';
import OrdersContext from '../../context';

function ProductList() {
  const { muted, text } = useContext(ThemeContext);
  const { orders } = useContext(OrdersContext).selected;

  return (
    <div style={{ marginTop: 20 }}>
      <h2>Produtos</h2>

      {
        orders?.map((order) => (
          <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 15 }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>{ order.quantity }x { order.title }</span>
              <span style={{ color: order.observation ? muted : text }}>R$ { parseFloat(order.price * order.quantity).toFixed(2).replace('.', ',') }</span>
            </div>
            <div style={{ marginLeft: 10 }}>
              {
                order.observation?.map((option) => (
                  <div style={{ display: 'flex', fontSize: 14, justifyContent: 'space-between', color: muted.hex() }}>
                    <span>+ { option.item }</span>
                    <span>
                      { order.quantity > 1 ? `${order.quantity}x ` : '' }
                      { option.price === 0 ? 'Grátis' : "R$ " + parseFloat(option.price).toFixed(2).replace('.', ',') }
                    </span>
                  </div>
                ))
              }
            </div>
            {
              order.observation ? (
                <div style={{ marginTop: 5, display: 'flex', justifyContent: 'space-between' }}>
                  <span></span>
                  <span>R$ { parseFloat(order.finalPrice).toFixed(2).replace('.', ',') }</span>
                </div>
              ) : <></>
            }
          </div>
        ))
      }
      

    </div>  
  );
}

export default ProductList;