import React, { useContext } from 'react';

import ModalComponent from './ModalComponent';
import DrawerComponent from './DrawerComponent';
import OrdersList from './OrdersList';

import ThemeContext from '../../context/ThemeContext';
import OrdersContext, { OrdersContextProvider } from './context';

import { Scrollbars } from 'react-custom-scrollbars';

import Loader from 'react-loader-spinner';

function Orders() {
  const { background, main } = useContext(ThemeContext);

  return (
    <div style={{ display: 'flex', flexDirection: 'row', maxHeight: '90vh' }}>
      <OrdersContextProvider>
        <ModalComponent />
        <DrawerComponent />

        <OrdersContext.Consumer>
          {
            ({ isLoading }) => isLoading ? (
              <div style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: background }} className="screen-container">
                <Loader
                  type="TailSpin"
                  color={main.hex()}
                  height={50}
                  width={50}
                />
              </div>
            ) : (
              <Scrollbars style={{ height: '90vh', backgroundColor: background, flexDirection: 'column', flex: 1 }} className="screen-container">
                <OrdersList />
              </Scrollbars>
            )
          }
        </OrdersContext.Consumer>
      </OrdersContextProvider>        
    </div>
  );
}

export default Orders;