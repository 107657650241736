import React from 'react';
import './index.css';

import LeftImage from './LeftImage';
import RightForm from './RightForm';

function authentication() {
  return (
    <div className="login-container">
      <LeftImage />
      <RightForm />
    </div>
  );
}

export default authentication;