import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Portal, TextField } from '@material-ui/core';
import React, { useContext, useState } from 'react';

import AuthContext from '../../../../../../context/AuthContext';
import ThemeContext from '../../../../../../context/ThemeContext';
import { useFetch } from '../../../../../../hooks';

function EmailReset() {
  const { token } = useContext(AuthContext);
  const { soft, surface, text } = useContext(ThemeContext);
  const [email, setEmail] = useState("");

  const [isError, setIsError] = useState(false);

  const [isDialog, setIsDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogMessage, setDialogMessage] = useState("");

  const handleDialog = (status = false, title = "Erro", message = "Erro inesperado, por favor contate o desenvolvedor.") => {
    setIsDialog(status);
    setDialogTitle(title);
    setDialogMessage(message);
  }

  const handleRedefine = () => {
    if (!(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email))) {
      return setIsError(true);
    }

    useFetch.post("/a/cli/sete", { token, email }, (response) => {
      switch (response.code) {
        case 'success':
          handleDialog(true, "Email Redefino", "Seu endereço de e-mail foi redefinido com sucesso");
          break;
        default:
          handleDialog(true);
          break;
      }
    })
  }

  const onChange = (e) => {
    setEmail(e.target.value);
    if (isError) setIsError(false);
  }

  return (
    <div style={{ padding: 20, borderRadius: 10, backgroundColor:  surface.hex() }}>
      <Portal>
        <Dialog open={isDialog} onClose={() => handleDialog()}>
          <DialogTitle>{ dialogTitle }</DialogTitle>
          <DialogContent>{ dialogMessage }</DialogContent>
          <DialogActions>
            <Button onClick={() => handleDialog()}>Ok</Button>
          </DialogActions>
        </Dialog>
      </Portal>

      <h2 style={{ fontWeight: 'normal', marginTop: 10 }}>
        Redefinir E-mail
      </h2>

      <div style={{ maxWidth: 400, display: 'flex', flexDirection: 'column' }}>
        <FormControl>
          <TextField
            label="Novo endereço"
            onChange={onChange}
            type="email"
            value={email}
          />
          { isError ? <FormHelperText error>Este endereço de e-mail é inválido</FormHelperText> : <></> }
          <FormHelperText>Nós nunca compartilharemos seu e-mail com ninguém.</FormHelperText>
        </FormControl>
        <Button
          disableElevation
          onClick={handleRedefine}
          style={{ backgroundColor: soft.hex(), color: text.hex(), marginTop: 20 }}
          variant="contained"
        >
          Redefinir
        </Button>
      </div>
      
    </div>
  );
}

export default EmailReset;