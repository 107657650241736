import React from 'react';

import { AuthContextProvider } from './AuthContext';
import { SocketContextProvider } from './SocketContext';
import { ThemeContextProvider } from './ThemeContext';

export default function Context ({ children }) {
  return (
    <AuthContextProvider>
      <SocketContextProvider>
        <ThemeContextProvider>
          { children }
        </ThemeContextProvider>
      </SocketContextProvider>
    </AuthContextProvider>
  );
}
