import React, { useContext } from 'react';
import ThemeContext from '../../../../context/ThemeContext';

function Header() {
  const { text, soft } = useContext(ThemeContext);
  
  return (
    <div style={{ padding: 15, backgroundColor: soft, display: 'flex', marginBottom: 15, borderRadius: 10 }}>
      <div style={{ display: 'flex', flex: 1, alignSelf: 'stretch' }}>
        <div style={{ flex: 1, paddingRight: 10 }}><span style={{ color: text }}>Produto</span></div>
        <div style={{ flex: 1, paddingRight: 10 }}><span style={{ color: text }}>Categoria</span></div>
        <div style={{ flex: 1, paddingRight: 10 }}><span style={{ color: text }}>Valor Promocional</span></div>
        <div style={{ flex: 1, paddingRight: 10 }}><span style={{ color: text }}>Valor</span></div>
      </div>
    </div>
  );
}

export default Header;
