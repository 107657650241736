import React, { useContext } from 'react';

import ThemeContext from '../../../../context/ThemeContext';
import OrderContext from '../../context';

function OrderDetails() {
  const { soft } = useContext(ThemeContext);
  const { selected } = useContext(OrderContext);

  const { number, observation, neighborhood, street, city, state } = JSON.parse(selected.andress.andress);

  return (
    <div style={{ backgroundColor: soft.hex(), padding: 15, borderRadius: 15 }}>
      <p style={{ fontSize: 15, marginTop: 0 }}>Forma de pagamento: { selected.paymentMethod }</p>
      <p style={{ fontSize: 15, marginTop: -10 }}>{`${street}, ${number}, ${neighborhood} - ${city} - ${state}`}</p>
      <p style={{ fontSize: 15, marginTop: -10, marginBottom: 0 }}>{ observation ? "Observações: " + observation : "Sem observações" }</p>
    </div>
  );
}

export default OrderDetails;