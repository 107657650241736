import { Button, createMuiTheme, Dialog, DialogActions, DialogContent, DialogTitle, Modal, TextField, ThemeProvider } from '@material-ui/core';
import React, { createRef, useContext, useState } from 'react';
import './styles.css';
import { Container } from './styles';

import AuthContext from '../../../context/AuthContext';
import ThemeContext from '../../../context/ThemeContext';
import ProductContext from '../context';

import { useFetch } from '../../../hooks';

function CategoryCreate({ visible, onDismiss }) {
  const { token } = useContext(AuthContext);
  const { main, mode, soft, surface, text } = useContext(ThemeContext);
  const { findProducts } = useContext(ProductContext);

  const theme = createMuiTheme({
    palette: {
      background: { default: surface.hex(), paper: surface.hex() },
      secondary: { main: main.hex() },
      type: mode
    }
  });

  const imageRef = createRef();
  const defaultImage = "https://s2.glbimg.com/f63MNW7-vM39Q9tgRZvQx5l8amM=/620x443/s.glbimg.com/po/tt/f/original/2012/11/05/fundo_transparente.png";

  const [image, setImage] = useState(defaultImage);

  const [title, setTitle] = useState("");

  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState("");

  const showError = (message = "Algo de errado aconteceu.") => {
    setMessage(message);
    setIsError(true);
  }

  const onChange = () => {
    let file = imageRef.current.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = (e) => {
      setImage(reader.result);
    }
  }

  const onSubmit = () => {
    const fileData = imageRef.current.files[0];

    if (fileData === undefined) {
      showError('Selecione uma imagem.');
      return;
    } 

    if (title === '') {
      showError('Selecione um nome para a categoria');
      return;
    }

    const data = new FormData();
    data.append("file", fileData);
    data.append("name", title);
    
    useFetch.postFormData('/a/cat/create/' + token, data, (response) => {
      if (response.code === 'error') {
        showError(response.message);
      } else {
        findProducts();
        onDismiss();
      }
    });
  }

  return (
    <ThemeProvider theme={theme}>
      <Modal
        open={visible}
        onClose={onDismiss}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Container style={{ backgroundColor: surface.hex() }}>
          <Dialog open={isError} onClose={() => setIsError(false)}>
            <DialogTitle>Erro</DialogTitle>
            <DialogContent>{ message }</DialogContent>
            <DialogActions>
              <Button onClick={() => setIsError(false)}>
                Ok
              </Button>
            </DialogActions>
          </Dialog>
          <div className="left-container">
            <img
              style={{ backgroundColor: soft.hex() }}
              className="input-image"
              src={image}
              alt="Imagem da nova categoria"
            />
            
            <input ref={imageRef} onChange={onChange} type="file" name="file" id="file" className="custom-file-input"/>
          </div>

          <div className="right-container">
            <label style={{ backgroundColor: soft.hex(), color: text.hex() }} htmlFor="file" className="custom-file-label">Escolha uma imagem</label>
            <TextField
              value={title}
              onChange={e => setTitle(e.target.value)}
              style={{ margin: 5, marginRight: 0, flex: 1, alignSelf: 'stretch' }}
              variant="filled"
              label="Nome da categoria"
            />

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button onClick={onDismiss}>
                Cancelar
              </Button>

              <Button style={{ backgroundColo: soft.hex(), color: text.hex() }} disableElevation variant="contained" onClick={onSubmit}>
                Criar
              </Button>
            </div>
          </div>
          
        </Container>
      </Modal>
    </ThemeProvider>
  );
}

export default CategoryCreate;