import { Button, createMuiTheme, Dialog, DialogActions, DialogContent, DialogTitle, ThemeProvider, Typography } from '@material-ui/core';
import React, { useContext } from 'react';

import ThemeContext from '../../../../.../../../../../context/ThemeContext';
import ProductContext from '../../../../../context';
import { OptionGroupContext } from '../index';

import { useFetch } from '../../../../../../../hooks';

function OptionGroupDelete({ optionGroup }) {
  const { main, mode, soft, surface, text } = useContext(ThemeContext);
  const { findProducts, select, selected: product } = useContext(ProductContext);
  const { delete: visible, changeModal } = useContext(OptionGroupContext);
  const onDismiss = () => changeModal("delete", false);

  const theme = createMuiTheme({
    palette: {
      background: { default: surface.hex(), paper: surface.hex() },
      secondary: { main: main.hex() },
      type: mode
    }
  });

  const handleDelete = () => {
    useFetch.delete('/a/opt/grp/' + optionGroup.optionGroupId, (response) => {
      if (response.code === 'success') {
        let options = product.options.filter((item) => item.optionGroupId !== optionGroup.optionGroupId);
        select({ ...product, options });
        onDismiss();
        findProducts();
      } else {
        alert("Algo deu errado durante a exclusão do grupo de categorias");
      }
    });
  }
  
  return (
    <ThemeProvider theme={theme}>
      <Dialog open={visible} onClose={onDismiss}>
        <DialogTitle>Tem certeza disso</DialogTitle>
        <DialogContent>
          <Typography>Esta ação excluirá esse grupo de opções e todas as opções cadastradas nele.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onDismiss}>Cancelar</Button>
          <Button style={{ backgroundColor: soft.hex(), color: text.hex() }} onClick={handleDelete} variant="contained" disableElevation>Sim, excluir</Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}

export default OptionGroupDelete;