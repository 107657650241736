import React, { useContext, useEffect } from 'react';
import './index.css';

import { NavLink, useLocation, withRouter,  } from 'react-router-dom';

import Icon from '@mdi/react';
import { mdiBellOutline } from '@mdi/js';

import AuthContext from '../../context/AuthContext';
import SocketContext from '../../context/SocketContext';
import ThemeContext from '../../context/ThemeContext';
import { Badge, IconButton } from '@material-ui/core';

import logo from '../../assets/enterprise/logo.png';
import logo2 from '../../assets/enterprise/logo2.png';

function Header() {
  const { logout } = useContext(AuthContext);
  const { badge, clearBadge } = useContext(SocketContext);
  const { background, main, mode, muted, text } = useContext(ThemeContext);

  const { pathname } = useLocation();

  const handleNavLinkClick = (newClass) => {
    document.querySelector('.navlink-wrapper').className = `navlink-wrapper ${newClass}`;
  }

  useEffect(() => {
    switch(pathname) {
      default:
        document.querySelector('.navlink-wrapper').className = 'navlink-wrapper to-bar-one';
        break;
      case '/products':
        document.querySelector('.navlink-wrapper').className = 'navlink-wrapper to-bar-two';
        break;
      case '/statistics':
        document.querySelector('.navlink-wrapper').className = 'navlink-wrapper to-bar-three';
        break;
      case '/settings':
        document.querySelector('.navlink-wrapper').className = 'navlink-wrapper to-bar-four';
        break;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <header style={{ backgroundColor: background }}>
      <ul>
        <img style={{ height: 40, width: 150, marginRight: 30, borderRadius: 10 }} src={ mode === 'light' ? logo2 : logo} alt="Logo" />
        {
          [
            { path: '/', name: 'Pedidos', bar: 'to-bar-one' },
            { path: '/products', name: 'Produtos', bar: 'to-bar-two' },
            { path: '/statistics', name: 'Estatísticas', bar: 'to-bar-three' },
            { path: '/settings', name: 'Ajustes', bar: 'to-bar-four' },
          ].map(({ path, name, bar }) => (
            <NavLink key={path} onClick={() => {
              handleNavLinkClick(bar);
              if (path === '/') clearBadge();
            }} to={path} style={{ textDecoration: 'none' }}>
              <li style={{ color: text, paddingBottom: 6, textAlign: 'center' }}>{ name }</li>
            </NavLink>
          ))
        }
        <div
          className="navlink-wrapper"
          style={{
            borderBottomColor: main,
            borderBottomStyle: 'solid',
            borderBottomWidth: 2
          }}
        />
      </ul>

      <ul>
        <li onClick={logout} className="btn" style={{ backgroundColor: main, color: 'white', marginRight: 20 }}>Sair</li>
        <NavLink onClick={() => handleNavLinkClick('to-bar-one')} to="/">
          <IconButton onClick={clearBadge}>
            <Badge badgeContent={badge} color="error">
              <Icon path={mdiBellOutline} title="Notificações" size={1} color={muted.hex()} />
            </Badge>
          </IconButton>
        </NavLink>
      </ul>
    </header>
  );
}

export default withRouter(Header);
