import React, { useContext, useState } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import Portal from '@material-ui/core/Portal';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import AuthContext from '../../../../../../context/AuthContext';
import ThemeContext from '../../../../../../context/ThemeContext';
import { useFetch } from '../../../../../../hooks';

function PasswordReset() {
  const { token, logout } = useContext(AuthContext);
  const { soft, surface, text } = useContext(ThemeContext);

  // Dialog

  const [isDialog, setIsDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogMessage, setDialogMessage] = useState("");

  const handleDialog = (status = false, dtitle = "Erro", message = "Algum erro inesperado aconteceu, por favor, entre em contato com o desenvolvedor.") => {
    setIsDialog(status);
    setDialogTitle(dtitle);
    setDialogMessage(message);
  }

  // Inputs

  const defaultform = {
    currentPassword: { value: "", visible: false },
    newPassword: { value: "", visible: false },
    repeatPassword: { value: "", visible: false }
  };
  const [form, setForm] = useState(defaultform)

  // Errors

  const [currentPasswordError, setCurrentPasswordError] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState(false);
  const [repeatPasswordError, setRepeatPasswordError] = useState(false);
  const [equalsPasswordError, setEqualsPasswordError] = useState(false);

  // Controller

  const setVisibility = (key) => {
    setForm({ ...form, [key]: { ...form[key], visible: !form[key].visible } });
  }

  const onChange = (e) => {
    const { id, value } = e.target;
    setForm({ ...form, [id]: { ...form[id], value } });

    if (currentPasswordError) setCurrentPasswordError(false);
    if (repeatPasswordError) setRepeatPasswordError(false);
    if (newPasswordError) setNewPasswordError(false);
    if (equalsPasswordError) setEqualsPasswordError(false);
  }

  const handleUpdatePassword = () => {
    // min 8 - max 20 - match number and letters
    if (!form.newPassword.value.match(/[0-9]/g) || !form.newPassword.value.match(/[a-zA-Z]/g) || form.newPassword.value.length < 8 || form.newPassword.value.length > 20) return setNewPasswordError(true);
    // verify repeat password
    if (form.newPassword.value !== form.repeatPassword.value) return setRepeatPasswordError(true);
    // compare old password with new password
    if (form.currentPassword.value === form.newPassword.value) return setEqualsPasswordError(true);

    useFetch.post("/a/cli/setp", {
      token,
      currentPassword: form.currentPassword.value,
      newPassword: form.newPassword.value
    }, (response) => {
      switch(response.code) {
        case 'success':
          setForm(defaultform);
          handleDialog(true, "Sucesso", "Sua senha foi alterada com sucesso!");
          break;

        default:
          switch(response.message) {
            case 'invalid-token':
              handleDialog(true, "Token inválido", "Para sua segurança, realize o login novamente.");
              setTimeout(() => {
                logout();
              }, [3000]);
              break;

            case 'wrong-password':
              handleDialog(true, "Senha incorreta", "Senha incorreta para essa conta.");
              break;

            default:
              handleDialog(true);
              break;
          }
        break;
      }
    })
  }

  return (
    <div style={{ padding: 20, borderRadius: 10, backgroundColor:  surface.hex() }}>
      <Portal>
        <Dialog open={isDialog} onClose={() => handleDialog()}>
          <DialogTitle>{ dialogTitle }</DialogTitle>
          <DialogContent>{ dialogMessage }</DialogContent>
          <DialogActions><Button onClick={() => handleDialog()}>Ok</Button></DialogActions>
        </Dialog>
      </Portal>

      <div style={{ display: 'flex', flexDirection: 'column', maxWidth: 400 }}>
        <h2 style={{ fontWeight: 'normal', marginTop: 10 }}>Atualizar senha da conta</h2>

        <FormControl margin="dense">
          <InputLabel htmlFor="currentPassword">Senha atual</InputLabel>
          <Input
            value={form.currentPassword.value}
            onChange={onChange}
            id="currentPassword"
            type={form.currentPassword.visible ? "text" : "password"}
            variant="standard"
            size="small"
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={() => setVisibility('currentPassword')}>
                  { form.currentPassword.visible ? <Visibility /> : <VisibilityOff /> }
                </IconButton>
              </InputAdornment>
            }
          />
          { currentPasswordError ? <FormHelperText error>Senha incorreta</FormHelperText> : <></> }
        </FormControl>

        <FormControl margin="dense">
          <InputLabel htmlFor="newPassword">Nova senha</InputLabel>
          <Input
            value={form.newPassword.value}
            onChange={onChange}
            id="newPassword"
            type={form.newPassword.visible ? "text" : "password"}
            variant="standard"
            size="small"
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={() => setVisibility('newPassword')}>
                  { form.newPassword.visible ? <Visibility /> : <VisibilityOff /> }
                </IconButton>
              </InputAdornment>
            }
          />
          
          { equalsPasswordError ? <FormHelperText error>A nova senha não pode ser igual a antiga.</FormHelperText> : <></> }
          <FormHelperText error={newPasswordError}>A senha deve conter números e letras e no mínimo 8 caracteres e no máximo 20.</FormHelperText>
        </FormControl>

        <FormControl margin="dense">
          <InputLabel htmlFor="repeatPassword">Repita a nova senha</InputLabel>
          <Input
            value={form.repeatPassword.value}
            onChange={onChange} 
            id="repeatPassword"
            type={form.repeatPassword.visible ? "text" : "password"}
            variant="standard"
            size="small"
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={() => setVisibility('repeatPassword')}>
                  { form.repeatPassword.visible ? <Visibility /> : <VisibilityOff /> }
                </IconButton>
              </InputAdornment>
            }
          />
          { repeatPasswordError ? <FormHelperText error>As senhas não coincidem</FormHelperText> : <></> }
        </FormControl>

        <FormHelperText style={{ marginTop: 20 }}>Tenha certeza que se lembrará dessa senha futuramente, você precisará dela para realizar login ou para redefini-la.</FormHelperText>
        <Button
          onClick={handleUpdatePassword}
          disableElevation
          style={{ backgroundColor: soft.hex(), color: text.hex(), marginTop: 20 }}
          variant="contained"
        >Atualizar senha</Button>
      </div>
    </div>
  );
}

export default PasswordReset;
