import React from 'react';
import maemu from '../../assets/images/mae_mu.jpg';

function LeftImage() {
  const image = {
    image: maemu,
    author: 'Mae Mu',
    link: "https://unsplash.com/@picoftasty"
  };

  return (
    <div>
      <div>
        <img src={image?.image} alt="Imagem" className="image-box" />
        <div className="image-box-overlay">
          <small onClick={() => window.open(image.link, "_blank")}>Photo by {image?.author}</small>
        </div>
      </div>
    </div>
  );
}

export default LeftImage;