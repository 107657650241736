import React, { useContext, useState } from 'react';
import { Badge, OrderItem as Container, Text } from './styles';
import { Popover } from '@material-ui/core';
import { Icon } from '@mdi/react';

import { formatDistanceToNow } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { switchStatus } from './functions';

import { mdiInformationOutline } from '@mdi/js';

import OrdersContext from '../../context';

function OrderItem({ order }) {
  const { setIsModalActive, select } = useContext(OrdersContext);

  const [popoverAnchor, setPopoverAnchor] = useState(null);
  const { identifier, createdAt, orders, paymentMethod, status, user } = order;

  const isPopoverOpen = Boolean(popoverAnchor);
  const popoverId = isPopoverOpen ? 'order-id-popover' : undefined;

  const handleOpen = (e) => {
    setPopoverAnchor(e.currentTarget);
    e.stopPropagation();
    e.preventDefault();
  };

  const handleClose = () => setPopoverAnchor(null);
  
  const handleSelect = () => {
    select(order);
    setIsModalActive(true);
  }

  return (
    <Container>
      <Popover
        id={popoverId}
        open={isPopoverOpen}
        anchorEl={popoverAnchor}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <p style={{ marginLeft: 20, marginRight: 20 }}>código: { identifier }</p>
      </Popover>


      <div style={{ width: 130, marginRight: 20 }}>
        <Badge onClick={handleOpen}>
          { switchStatus(status) }
          <Icon path={mdiInformationOutline}
            style={{ marginLeft: 5}}
            size={'15px'}
          />
        </Badge>
      </div>
      <div style={{ display: 'flex', flex: 1, alignSelf: 'stretch', padding: 15 }} onClick={handleSelect}>
        <div style={{ flex: 1, paddingRight: 10 }}><Text>{ user.name.substring(0, user.name.indexOf(' ')) }</Text></div>
        <div style={{ flex: 1, paddingRight: 10 }}><Text>{ orders.length } itens</Text></div>
        <div style={{ flex: 1, paddingRight: 10 }}><Text>{ paymentMethod }</Text></div>
        <div style={{ flex: 1, paddingRight: 10 }}>
          <Text>Há { formatDistanceToNow(parseInt(createdAt), { locale: ptBR }) }</Text>
        </div>
      </div>
      
    </Container>
  );
}

export default OrderItem;