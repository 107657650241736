import React, { useContext, useState } from 'react';
import Loader from 'react-loader-spinner';
import { Scrollbars } from 'react-custom-scrollbars';

import DrawerComponent from './DrawerComponent';
import ModalComponent from './ModalComponent';
import ProductList from './ProductList';

import CategoryCreate from './CategoryCreate';
import CategoryUpdate from './CategoryUpdate';
import CategoryDelete from './CategoryDelete';

import ProductCreate from './ProductCreate';

import ThemeContext from '../../context/ThemeContext';
import ProductsContext, { ProductsContextProvider } from './context';

function Products() {
  const { background, main } = useContext(ThemeContext);

  const [isCategoryCreate, setIsCategoryCreate] = useState(false);
  const [categoryUpdate, setCategoryUpdate] = useState(null);
  const [categoryDelete, setCategoryDelete] = useState(null);
  const [productCreate, setProductCreate] = useState(false);

  return (
    <div style={{ backgroundColor: background, height: '90vh', display: 'flex' }}>
      <ProductsContextProvider>
        <CategoryCreate visible={isCategoryCreate} onDismiss={() => setIsCategoryCreate(false)} />
        <CategoryUpdate category={categoryUpdate} visible={Boolean(categoryUpdate)} onDismiss={() => setCategoryUpdate(null)} />
        <CategoryDelete category={categoryDelete} visible={Boolean(categoryDelete)} onDismiss={() => setCategoryDelete(null)} />
        <ProductCreate visible={productCreate} onDismiss={() => setProductCreate(false)} />

        <ModalComponent />
        <DrawerComponent
          openCategoryModal={() => setIsCategoryCreate(true)}
          updateCategory={(category) => setCategoryUpdate(category)}
          deleteCategory={(category) => setCategoryDelete(category)}
        />

        <ProductsContext.Consumer>
          {
            ({ isLoading }) => isLoading ? (
              <div style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: background }} className="screen-container">
                <Loader
                  type="TailSpin"
                  color={main.hex()}
                  height={50}
                  width={50}
                />
              </div>
            ) : (
              <Scrollbars style={{ height: '90vh', backgroundColor: background, flexDirection: 'column', flex: 1 }} className="screen-container">
                <ProductList createProduct={() => setProductCreate(true)} />
              </Scrollbars>
            )
          }
        </ProductsContext.Consumer>
      </ProductsContextProvider>
    </div>
  );
}

export default Products;