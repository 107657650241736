import React, { createRef, useContext, useState } from 'react';
import { Button, createMuiTheme, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Modal, Select, TextField, ThemeProvider } from '@material-ui/core';
import './styles.css';

import AuthContext from '../../../context/AuthContext';
import ThemeContext from '../../../context/ThemeContext';
import ProductsContext from '../context';

import { useFetch } from '../../../hooks';
import { currencyFormat } from '../../../utils';

function ProductCreate ({ visible, onDismiss }) {
  const { token } = useContext(AuthContext);
  const { main, mode, soft, surface, text } = useContext(ThemeContext);
  const { categories, findProducts, select } = useContext(ProductsContext);

  const theme = createMuiTheme({
    palette: {
      background: { default: surface.hex(), paper: surface.hex() },
      secondary: { main: main.hex() },
      type: mode
    }
  });
  
  const imageRef = createRef();
  const defaultImage = "https://s2.glbimg.com/f63MNW7-vM39Q9tgRZvQx5l8amM=/620x443/s.glbimg.com/po/tt/f/original/2012/11/05/fundo_transparente.png";

  const [image, setImage] = useState(defaultImage);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState('');
  const [sale, setSale] = useState('');
  const [categoryId, setCategoryId] = useState(0);

  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState("");

  const [titleError, setTitleError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [priceError, setPriceError] = useState(false);
  const [saleError, setSaleError] = useState(false);
  
  const showError = (message = "Algo de errado aconteceu.") => {
    setMessage(message);
    setIsError(true);
  }

  const onChange = () => {
    let file = imageRef.current.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = (e) => {
      setImage(reader.result);
    }
  }

  const handleCreate = () => {
    let hasError = false;

    if (categoryId === 0) {
      showError("Selecione uma categoria");
      return;
    }

    if (title === '') {
      hasError = true;
      setTitleError(true);
    } else {
      setTitleError(false);
    }
    
    if (description === '') {
      hasError = true;
      setDescriptionError(true);
    } else {
      setDescriptionError(false);
    }
    
    if (price === '') {
      hasError = true;
      setPriceError(true); 
    } else {
      setPriceError(false); 
    }
    
    if (sale === '') {
      hasError = true;
      setSaleError(true); 
    } else {
      setSaleError(false); 
    }

    if (hasError) {
      showError("Existem campos não preenchidos");
      return;
    }
    //
    let priceValue = 0;
    let promoValue = 0;

    try {
      priceValue = parseFloat(price.replace('R$ ', '').replace(',', '.'));
    } catch (e) {
      showError("Valor inválido");
      return;
    }

    try {
      promoValue = parseFloat(sale.replace('R$ ', '').replace(',', '.'));
    } catch (e) {
      showError("Valor promocional inválido");
      return;
    }

    // 

    const fileData = imageRef.current.files[0];

    if (fileData === undefined) {
      showError("Insira uma imagem");
      return;
    }

    const data = new FormData();

    data.append("categoryId", categoryId);
    data.append("file", fileData);
    data.append("title", title);
    data.append("description", description);
    data.append("price", priceValue);
    data.append("sale", promoValue);

    useFetch.postFormData('/a/product/create/' + token, data, (response) => {

      if (response.code === 'success') {
        let reader = new FileReader();
        reader.readAsDataURL(fileData);
        
        reader.onloadend = (e) => {
          let newProduct = {
            productId: response.id,
            category: categories.find(item => item.categoryId === categoryId).name,
            name: title,
            image: reader.result,
            details: description,
            price: priceValue,
            salePrice: promoValue,
            saleStatus: false,
            enabled: true,
            options: []
          }
          findProducts();
          select(newProduct);
          onDismiss();
        }
      } else {
        showError();
      }
    });

  }

  return (
    <ThemeProvider theme={theme}>
      <Modal
        open={visible}
        onClose={onDismiss}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <div className="container" style={{ backgroundColor: surface.hex() }}>
          <Dialog open={isError} onClose={() => setIsError(false)}>
            <DialogTitle>Erro</DialogTitle>
            <DialogContent>{ message }</DialogContent>
            <DialogActions>
              <Button onClick={() => setIsError(false)}>
                Ok
              </Button>
            </DialogActions>
          </Dialog>

          <div className="top">
            <div className="left">
              <img src={image} style={{ width: 200, height: 200, backgroundColor: soft.hex() }} alt="" />
              <input ref={imageRef} onChange={onChange} type="file" name="file" id="file" />
              <label htmlFor="file" style={{ backgroundColor: soft.hex(), color: text.hex(), width: 200, marginTop: 20 }}>Selecione uma imagem</label>
            </div>

            <div className="right">
              <TextField
                value={title}
                onChange={e => setTitle(e.target.value)}
                variant="filled"
                label="Nome"
                placeholder="Insira o nome do produto"
                style={{ width: 300 }}
                error={titleError}
              />

              <Select defaultValue={0} variant="filled" style={{ marginTop: 10 }} onChange={e => setCategoryId(e.target.value)}>
                <MenuItem value={0}>Selecione a categoria</MenuItem>
                {
                  categories.map((item) => (
                    <MenuItem key={Math.random()} value={item.categoryId}>{ item.name }</MenuItem>
                  ))
                }
              </Select>

              <TextField
                value={description}
                onChange={e => setDescription(e.target.value)}
                variant="filled"
                label="Descrição"
                placeholder="Insira uma descrição para o produto"
                style={{ width: 300, flex: 1, marginTop: 10 }}
                multiline
                error={descriptionError}
              />

              <div style={{ marginTop: 10 }}>
                <TextField
                  value={price}
                  onChange={e => setPrice(currencyFormat(e.target.value))}
                  variant="filled"
                  label="Valor"
                  placeholder="R$ 0,00"
                  style={{ width: 140 }}
                  error={priceError}
                />
                <TextField
                  value={sale}
                  onChange={e => setSale(currencyFormat(e.target.value))}
                  variant="filled"
                  label="Valor Promo"
                  placeholder="R$ 0,00"
                  style={{ width: 140, marginLeft: 20 }}
                  error={saleError}
                />
              </div>
            </div>
          </div>

          <div className="bottom">
            <Button onClick={onDismiss}>Cancelar</Button>
            <Button style={{ backgroundColor: soft.hex(), color: text.hex() }} onClick={handleCreate} variant="contained" disableElevation>Criar</Button>
          </div>
        </div>
      </Modal>
    </ThemeProvider>
  );
}

export default ProductCreate;