import styled from 'styled-components';

export const Container = styled.div`
  background-color: white;
  max-width: 95%;
  min-width: 400px;
  outline: none;
  padding: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
