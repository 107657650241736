import React, { useContext } from 'react';
import './styles.css';
import LinesEllipsis from 'react-lines-ellipsis';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@mdi/react';
import { mdiArrowDown, mdiArrowUp, mdiClose, mdiPencilOutline  } from '@mdi/js';

import ThemeContext from '../../../../context/ThemeContext';
import ProductsContext from '../../context';
import { ProductActionsContext } from '../index';

function ModalHeader() {
  const { muted, soft, text } = useContext(ThemeContext);
  const { select, selected, setIsModalActive, products } = useContext(ProductsContext);
  const { changeAction } = useContext(ProductActionsContext);

  if (!selected) return <></>;

  const { category, details, image, name, productId } = selected;
  const index = products.findIndex(item => item.productId === productId);
  const isLast = index === products.length - 1;

  const setSelectedProduct = (value) => {
    select(products[index + value]);
  }

  return (
    <div>
      <div style={{ height: 90, background: soft.hex() }} />
      <div className="product-header" style={{ padding: 50, paddingTop: 0, display: 'flex', marginBottom: 5, justifyContent: 'space-between' }}>
        <div style={{ display: 'flex' }}>

          <div style={{ width: 200, height: 200, overflow: 'hidden' }} className="img-container">
            <img style={{ margin: 0, borderRadius: 8, width: 200, height: 200, objectFit: 'cover' }} src={image} alt={name} />
            <div onClick={() => changeAction("image", true)} className="img-overlay" style={{ width: 200, height: 200 }}>
              <Icon path={mdiPencilOutline} title="Editar imagem" size={1} color={text.hex()} />
            </div>
          </div>

          
          <div style={{ marginLeft: 20 }}>
            <h1>
            <LinesEllipsis
              text={name}
              maxLine={1}
              ellipsis="..."
              trimRight
              basedOn="letters"
            />
            </h1>
            <div style={{ marginTop: 50 }}>
              <span style={{ backgroundColor: soft.hex(), paddingLeft: 10, paddingRight: 10, borderRadius: 20 }}>{ category }</span>
              <p>
                <LinesEllipsis
                  text={details}
                  maxLine={1}
                  ellipsis="..."
                  trimRight
                  basedOn="letters"
                />
              </p>

              <div style={{ display: 'flex' }}>
                <Button
                  onClick={() => changeAction("update", true)}
                  style={{ color: text.hex(), backgroundColor: soft.hex() }}
                  variant="contained" disableElevation
                >Editar</Button>

                <Button
                  onClick={() => changeAction("transfer", true)}
                  style={{ color: text.hex(), backgroundColor: soft.hex(), marginLeft: 10 }}
                  variant="contained" disableElevation
                >Transferir</Button>

                <Button
                  onClick={() => changeAction("delete", true)}
                  style={{ color: text.hex(), backgroundColor: soft.hex(), marginLeft: 10 }}
                  variant="contained" disableElevation
                >Excluir</Button>
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'flex-start' }}>
          <IconButton disabled={index === 0} onClick={() => setSelectedProduct(-1)} >
            <Icon path={mdiArrowUp} title="Ir para produto anterior" size={1} color={index === 0 ? muted.hex() : text.hex()} />
          </IconButton>
          <IconButton disabled={isLast} onClick={() => setSelectedProduct(+1)}>
            <Icon path={mdiArrowDown} title="Ir para próximo produto" size={1} color={isLast ? muted.hex() : text.hex()} />
          </IconButton>
          <IconButton onClick={() => setIsModalActive(false)}>
            <Icon path={mdiClose} title="Fechar detalhes" size={1} color={text.hex()} />
          </IconButton>
        </div>
      </div>
    </div>
  );
}

export default ModalHeader;