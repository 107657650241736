import React, { createContext, useContext, useEffect, useState } from 'react';

import SettingsContext from '../../../context';

const parseJSON = (string) => ["", null, undefined].some(item => item === string) ? [] : JSON.parse(string);  

const ContactContext = createContext({
  contacts: [],
  setContacts: (contacts) => null,

  selected: false,
  select: (contactGroup) => null,

  updateStored: () => null,
});

export const ContactContextProvider = ({ children }) => {
  const { client } = useContext(SettingsContext);

  const [contacts, setContacts] = useState([]);
  const [selected, select] = useState(null);

  useEffect(() => {
    let andress = parseJSON(client.andresses);
    setContacts(andress);
  }, [client]);


  return (
    <ContactContext.Provider value={{ contacts, setContacts, selected, select }}>
      { children }
    </ContactContext.Provider>
  )
}

export default ContactContext;
