export function switchStatus (status) {
  switch (status) {
    default:
      return 'Entregue';
    case 1:
      return 'Aguardando';
    case 2:
      return 'Em preparo';
    case 3:
      return 'Enviando'; 
    case 4:
      return 'Recusado';
    case 5:
      return 'Cancelado';
  }
}