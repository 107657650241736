import React from 'react';

import ProductDelete from './ProductDelete';
import ProductImage from './ProductImage';
import ProductTransfer from './ProductTransfer';
import ProductUpdate from './ProductUpdate';

function ProductActions() {
  return (
    <>
      <ProductDelete />
      <ProductImage />
      <ProductTransfer />
      <ProductUpdate />
    </>
  );
}

export default ProductActions;