import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useFetch } from '../../hooks';

import AuthContext from '../../context/AuthContext';

const ProductsContext = createContext({
  findProducts: () => {},
  isLoading: true,
  isModalActive: false,
  setIsModalActive: (boolean) => {},

  search: '',
  setSearch: (search) => {},

  filter: null,
  setFilter: (filter) => {},
  cfilter: null,
  setCfilter: (category) => {},

  products: [],
  categories: [],

  selected: null,
  select: (order) => {},
});

export function ProductsContextProvider ({ children }) {
  const { token } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(true);
  const [filter, setFilter] = useState(0);
  const [cfilter, setCfilter] = useState("Nenhum");
  
  const [isModalActive, setIsModalActive] = useState(false);

  const [search, setSearch] = useState("");
  const [list, setList] = useState([]);
  const [selected, select] = useState(null);
  const [categories, setCategories] = useState([]);
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => search !== "" ? setSearch("") : () => {}, [filter, cfilter]);

  const products = useMemo(() => {
    let data = [];

    if (filter === 0) {
      data = list;
    } else if (filter === 1) {
      data = list.filter(item => item.saleStatus === 1)
    } else if (filter === 2) {
      data = list.filter(item => item.enabled === true)
    } else if (filter === 3) {
      data = list.filter(item => item.enabled === false)
    }

    if (cfilter !== 'Nenhum') {
      data = data.filter(item => item.category === cfilter);
    }

    if (search !== "") {
      let regex = new RegExp(search, 'i');
      data = data.filter(({ name, category }) => regex.test(name) || regex.test(category));
    }

    return data;
  }, [filter, list, cfilter, search]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => findProducts(), []);

  const findProducts = () => {
    setIsLoading(true);
    useFetch.get('/a/product/' + token, (response) => {
      const temp_categories = [];
      const temp_products = [];
      
      response.categories.forEach((category) => {
        const { categoryId, image, name, products: cateegoryProducts } = category;
        temp_categories.push({ categoryId, image, name });

        cateegoryProducts.forEach((product) => {
          temp_products.push({ ...product, category: name });
        });

        setCategories(temp_categories);
        setList(temp_products.sort((a, b) => a.name > b.name ? 1 : -1 ));
      }, []);
      setIsLoading(false);
    });
  }

  return (
    <ProductsContext.Provider value={{ findProducts, isLoading, isModalActive, setIsModalActive, search, setSearch, filter, setFilter, cfilter, setCfilter, products, categories, selected, select }}>
      { children }
    </ProductsContext.Provider>
  );
}

export default ProductsContext;
