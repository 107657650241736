import React, { useContext } from 'react';
import Drawer from '../../../components/Drawer';

import OptionGroup from './OptionGroup';

import ThemeContext from '../../../context/ThemeContext';
import SettingsContext from '../context';

function DrawerComponent() {
  const { main, text } = useContext(ThemeContext);
  const { actualPage, setActualPage } = useContext(SettingsContext);

  return (
    <Drawer>
      <OptionGroup>
        {
          [
            { key: 0, label: 'Geral' },
            { key: 1, label: 'Contato' },
            { key: 2, label: 'Segurança' },
            { key: 3, label: 'Aparência' },
          ].map(({ key, label }) => (
              <div
                key={key}
                className="opt-item"
                onClick={() => setActualPage(key)}
                style={{ cursor: 'pointer', paddingTop: 2, height: 30, color: key === actualPage ? main.hex() : text.hex() }}
              >{ label }</div>
          ))
        }
      </OptionGroup>
    </Drawer>
  );
}

export default DrawerComponent;