import React, { createRef, useContext, useEffect, useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Modal, TextField, createMuiTheme, ThemeProvider } from '@material-ui/core';
import { Container } from './styles';

import ThemeContext from '../../../context/ThemeContext';
import ProductsContext from '../context';

import { useFetch } from '../../../hooks';

function CategoryUpdate({ category, visible, onDismiss }) {
  const { main, mode, soft, surface, text } = useContext(ThemeContext);
  const { findProducts, setCfilter } = useContext(ProductsContext);

  const theme = createMuiTheme({
    palette: {
      background: { default: surface.hex(), paper: surface.hex() },
      secondary: { main: main.hex() },
      type: mode
    }
  });
  
  const imageRef = createRef();

  const [image, setImage] = useState(null);
  const [title, setTitle] = useState(null);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (category) {
      setImage(category.image);
      setTitle(category.name);
    }
  }, [category]); 

  const showError = (message = "Algo de errado aconteceu.") => {
    setMessage(message);
    setIsError(true);
  }

  const onChange = () => {
    let file = imageRef.current.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = (e) => {
      setImage(reader.result);
    }
  }

  const onSubmit = () => {
    const fileData = imageRef.current.files[0];
    const data = new FormData();
    data.append("categoryId", category.categoryId);
    data.append("title", category.name);
    let hasSomeUpdate = false;

    if (title !== category.name) {
      data.set("title", title);
      hasSomeUpdate = true;
    }

    if (fileData !== undefined) {
      data.append("file", fileData);
      data.append("update_image", '1');
      hasSomeUpdate = true;
    } 

    if (!hasSomeUpdate) {
      showError("Não foram feitas modificações tanto no título quanto na imagem.");
      return;
    }
    
    useFetch.postFormData('/a/cat/update', data, (response) => {
      if (response.code === 'error') {
        showError(response.message);
      } else {
        setCfilter("Nenhum");
        findProducts();
        onDismiss();
      }
    });
  }

  return (
    <ThemeProvider theme={theme}>
      <Modal
        open={visible}
        onClose={onDismiss}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Container style={{ backgroundColor: surface.hex() }}>
          <Dialog open={isError} onClose={() => setIsError(false)}>
            <DialogTitle>Erro</DialogTitle>
            <DialogContent>{ message }</DialogContent>
            <DialogActions>
              <Button onClick={() => setIsError(false)}>
                Ok
              </Button>
            </DialogActions>
          </Dialog>
          <div className="left-container">
            <img
              className="input-image"
              src={image}
              alt="Imagem da nova categoria"
            />
            
            <input ref={imageRef} onChange={onChange} type="file" name="file" id="file" className="custom-file-input"/>
          </div>

          <div className="right-container">
            <label style={{ backgroundColor: soft.hex(), color: text.hex() }} htmlFor="file" className="custom-file-label">Escolha uma imagem</label>
            <TextField
              value={title}
              onChange={e => setTitle(e.target.value)}
              style={{ margin: 5, flex: 1, alignSelf: 'stretch' }}
              variant="filled"
              label="Nome da categoria"
            />

            <Button onClick={onSubmit}>
              Atualizar
            </Button>
          </div>
          
        </Container>
      </Modal>
    </ThemeProvider>
  );
}

export default CategoryUpdate;