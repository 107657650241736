export const dateReverse = (min3date) => {
  switch (min3date) {
    case "mon":
      return "Segunda-feira";
    case "tue":
      return "Terça-feira";
    case "wed":
      return "Quarta-feira";
    case "thu":
      return "Quinta-feira";
    case "fri":
      return "Sexta-feira";
    case "sat":
      return "Sábado";
    default:
      return "Domingo";
  }
}