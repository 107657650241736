import React, { useContext, useRef } from 'react';
import ThemeContext from '../../../../context/ThemeContext';
import { Badge } from '../styles';
import { useCopyToClipboard } from '../../../../hooks';

import Icon from '@mdi/react';
import { mdiClipboardTextOutline, mdiClipboardCheckOutline  } from '@mdi/js';

function BadgeToClipboard({ identifier }) {
  const { soft, text } = useContext(ThemeContext);
  const [isCopied, handleCopy] = useCopyToClipboard(3000);
  const badgeRef = useRef(null);

  return (
    <div style={{ display: 'flex', alignItems: 'baseline', alignSelf: 'baseline' }}>
      <Badge ref={badgeRef} onClick={() => handleCopy(identifier)} style={{ backgroundColor: soft }}>
        <Icon path={mdiClipboardTextOutline}
          style={{ marginRight: 10 }}
          title="Área de transferência"
          size={0.8}
          color={text}
        />
        { identifier }
      </Badge>
      {
        !isCopied ? <></> : (
          <Badge style={{ cursor: 'default',  backgroundColor: soft }}>
            <Icon path={mdiClipboardCheckOutline}
              style={{ marginRight: 10 }}
              title="Área de transferência"
              size={0.8}
              color={text}
            />
            Copiado
          </Badge>
        )
      }
    </div>
  );
}

export default BadgeToClipboard;