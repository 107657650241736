import React, { useContext } from 'react';
import './index.css';

import ThemeContext from '../../context/ThemeContext';
import { Scrollbars } from 'react-custom-scrollbars';

const Drawer = ({ children }) => {
  const { background } = useContext(ThemeContext);

  return (
    <div style={{ backgroundColor: background.hex() }} className="drawer">
      <Scrollbars style={{ paddingRight: 10 }}>
        { children }
      </Scrollbars>
    </div>
  );
}

export default Drawer;
