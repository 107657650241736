import { IconButton } from '@material-ui/core';
import { mdiMinusCircleOutline, mdiPencilOutline } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useContext } from 'react';
import ThemeContext from '../../../../context/ThemeContext';
import ProductsContext from '../../context';

// import { Container } from './styles';

function OptionGroupItem({ deleteCategory, updateCategory, item }) {
  const { name } = item;
  
  const { main, text } = useContext(ThemeContext);
  const { cfilter, setCfilter } = useContext(ProductsContext);

  const handleDelete = (e) => {
    e.stopPropagation();

    deleteCategory(item);
  }

  const handleUpdate = (e) => {
    e.stopPropagation();

    updateCategory(item)
  }
  
  return (
    <div onClick={() => setCfilter(name)}
      className="opt-item"
      style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: 2, color: cfilter === name ? main : text }}
    >
      {
        name === 'Nenhum' ? (
          <span style={{ marginLeft: 10, marginBottom: 10 }}>{ name }</span>
        ) : (
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IconButton onClick={handleDelete} size="small">
                <Icon path={mdiMinusCircleOutline} size={1} color="red" title={`Excluir categoria ${name}`} />
              </IconButton>
              <span style={{ marginLeft: 10 }}>{ name }</span>
            </div>
            <IconButton onClick={handleUpdate} size="medium">
              <Icon path={mdiPencilOutline} size={1} color={text.hex()} title={`Alterar categoria ${name}`} />
            </IconButton>
          </>
        )
      }
      
    </div>
  );
}

export default OptionGroupItem;