import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import React, { useContext, useMemo } from 'react';

import ThemeContext from '../../../../context/ThemeContext';
import StatisticsContext from '../../context';

function TopTwo() {
  // this components shows on /statistics the orders and products quantity

  const { surface, muted, text } = useContext(ThemeContext);
  const { interval, orders } = useContext(StatisticsContext);

  const switchInterval = (arg0) => {
    switch (arg0) {
      case 1: return 'hoje';
      case 2: return 'essa semana';
      case 3: return 'esse mês';
      case 4: return 'esse ano';
      default: return 'contabilizados';
    }
  }

  const betterDay = useMemo(() => {
    let response = Object.values(orders.filter((item) => [0,1,2,3].some(status => status === item.status)).reduce((obj, item) => {
      const weekday = format(parseInt(item.createdAt), 'EEEE', { locale: ptBR });

      if (obj[weekday]) {
        obj[weekday].value += 1;
      } else {
        obj[weekday] = {
          name: weekday,
          value: 1
        }
      }

      return obj;
    }, []));

    response = response.sort((a, b) => a.value < b.value ? 1 : -1);
    return response[0];
  }, [orders]);

  return (
    <div className="top-two" style={{ backgroundColor: surface.hex(), display: 'flex', alignItems: 'center', paddingTop: 20, justifyContent: 'space-around', }}>
      <div style={{ display: 'flex', flexDirection: 'column', }}>
        <h1 style={{ color: text, marginBottom: 10 }}>{ orders.length }</h1>
        <span style={{ color: muted }}>Pedidos { switchInterval(interval) } </span>
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', }}>
        <h1 style={{ color: text, marginBottom: 10 }}>
          {
            orders.reduce((a, b) => a + b.orders.reduce((c, d) => c + d.quantity, 0), 0)
          }
        </h1>
        <span style={{ color: muted }}>Produtos</span>
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', }}>
        <h1 style={{ color: text, marginBottom: 10, textTransform: 'capitalize' }}>{ betterDay ? betterDay.name : '-' }</h1>
        <span style={{ color: muted }}>Melhor dia da semana</span>
      </div>
    </div>
  );
}

export default TopTwo;