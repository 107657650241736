import { createMuiTheme, Switch, ThemeProvider } from '@material-ui/core';
import React, { useContext, useState } from 'react';

import AuthContext from '../../../../../../context/AuthContext';
import ThemeContext from '../../../../../../context/ThemeContext';
import SettingsContext from '../../../../context';
import { useFetch } from '../../../../../../hooks';

function Schedule() {
  const { token } = useContext(AuthContext);
  const { main, mode } = useContext(ThemeContext);
  const { client } = useContext(SettingsContext);

  const [isChecked, setIsChecked] = useState(client.forceClose ? true : false);
  
  const theme = createMuiTheme({
    palette: {
      primary: { main: main.hex() },
      secondary: { main: main.hex() },
      type: mode
    }
  });

  const onSwitch = (e) => {
    let tinyIntBoolean = isChecked ? 0 : 1;
    setIsChecked(!isChecked);

    useFetch.post("/a/cli/f", { token, forceClose: tinyIntBoolean, }, (response) => {
      if (response.code === 'error') {
        setIsChecked(tinyIntBoolean === 1 ? false : true);
        alert("Error thrown by force pause switch API call.");
      }
    });
  }

  return (
    <div style={{ marginTop: 20, display: 'flex', justifyContent: 'space-between' }}>
      <div>
        Forçar pausa <br />
        <small>Enquanto essa opção estiver ativa, o aplicativo não fará pedidos, utilize isso durante feriados ou períodos de recesso.</small>
      </div>

      <ThemeProvider theme={theme}>
        <Switch color="primary" checked={isChecked} onChange={onSwitch} />
      </ThemeProvider>
    </div>
  );
}

export default Schedule;