import React, { useContext, useState } from 'react';

import ThemeContext from '../../../../context/ThemeContext';
import OrdersContext from '../../context';

import Popover from './Popover';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@mdi/react';

import { mdiClose, mdiDotsVertical  } from '@mdi/js';

function switchStatus (status) {
  switch (status) {
    default: return 'Entregue';
    case 1: return 'Aguardando confirmação';
    case 2: return 'Sendo preparado';
    case 3: return 'Sendo entregue';
    case 4: return 'Recusado';
    case 5: return 'Cancelado';
  }
}

function ModalHeader() {
  const { text } = useContext(ThemeContext);
  const { selected, setIsModalActive } = useContext(OrdersContext);

  const [popoverAnchor, setPopoverAnchor] = useState(null);

  const handleClick = e => setPopoverAnchor(e.currentTarget);
  const handleClose = _ => setPopoverAnchor(null);

  if (!selected) return <></>;

  const { status } = selected;

  return (
    <div style={{ display: 'flex', marginBottom: 5, justifyContent: 'space-between', alignItems: 'center' }}>
      <Popover anchor={popoverAnchor} open={Boolean(popoverAnchor)} onClose={handleClose} />

      <span>
        {switchStatus(status)}
      </span>
      <div>
        <IconButton onClick={handleClick}>
          <Icon path={mdiDotsVertical} title="Opções" size={1} color={text.hex()} />
        </IconButton>

        <IconButton onClick={() => setIsModalActive(false)}>
          <Icon path={mdiClose} title="Fechar detalhes" size={1} color={text.hex()} />
        </IconButton>
      </div>
    </div>
  );
}

export default ModalHeader;