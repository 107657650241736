import React, { useContext, useEffect, useRef, useState } from 'react';
import { LineChart, XAxis, Line, Tooltip } from 'recharts';
import { format } from 'date-fns';

import ThemeContext from '../../../../context/ThemeContext';
import StatisticsContext from '../../context';
import { useDimensions } from '../../../../hooks';

import { colors } from '../colors';

const Total = () => {
  const { muted, surface, text } = useContext(ThemeContext);
  const { payMethods: contextPayMethods, orders: contextOrders } = useContext(StatisticsContext);

  const containerRef = useRef();
  const size = useDimensions(containerRef);

  const [orders, setOrders] = useState([]);

  useEffect(() => {
    let payMethods = contextPayMethods;
    let reduced = Object.values(contextOrders.filter((item) => [0,1,2,3].some(status => status === item.status))
      .reduce((obj, item) => {
        let day = new Date(parseInt(item.createdAt)).setHours(0,0,0,0);

        let object = {
          day,
          name: format(day, "dd/MM/yyyy"),
          // eslint-disable-next-line no-sequences
          ...payMethods.reduce((a, { name }) => (a[name] = item.paymentMethod === name ? 1 : 0, a), {})
        };

        if (obj[day]) {
          obj[day][item.paymentMethod] += 1;
        } else {
          obj[day] = object;
        }

        return obj;
      }, []));

    setOrders(reduced);
  }, [contextOrders, contextPayMethods]);

  return (
    <div ref={containerRef} className="chart" style={{ backgroundColor: surface.hex(), display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <div style={{ marginTop: 40, marginBottom: -40, display: 'flex', flexDirection: 'column' }}>
        <h1 style={{ color: text, marginBottom: 15 }}>Vendas</h1>
        <span style={{ color: muted }}>Número de vendas por método de pagamento</span>
      </div>
      <LineChart width={size.width - 120} height={400} data={orders.sort((a, b) => a.day > b.day ? 1 : -1 )}>
        <XAxis stroke={text.hex()} dataKey="name" label={({value}) => value} />
        <Tooltip />

        {
          contextPayMethods.map(({ name }, index) => (
            <Line key={name + index} type="monotone" dataKey={name} stroke={colors[index]} />
          ))
        }
      </LineChart>
    </div>
  );
}

export default Total;