import React, { useContext } from 'react';
import './index.css';

import Drawer from '../../../components/Drawer';
import OptionGroup from './OptionGroup';

import ThemeContext from '../../../context/ThemeContext';
import ProductsContext from '../context';

import OptionGroupItem from './OptionGroupItem';

function DrawerComponent({ openCategoryModal, updateCategory, deleteCategory }) {
  const { main, text } = useContext(ThemeContext);
  const { categories, filter, setFilter } = useContext(ProductsContext);

  return (
    <Drawer>
      <div style={{ marginRight: 15, marginBottom: 60 }}>
        <h2 style={{ color: text, margin: 0 }}>Filtros</h2>
        <br />

        <OptionGroup>
          {
            [
              { key: 0, label: 'Nenhum' },
              { key: 1, label: 'Em promoção' },
              { key: 2, label: 'Habilitados' },
              { key: 3, label: 'Desabilitados' },
            ].map(({ key, label }) => (
                <div
                  key={key}
                  onClick={() => setFilter(key)}
                  className="opt-item"
                  style={{ cursor: 'pointer', paddingTop: 2, height: 30, color: filter === key ? main : text }}
                >{ label }</div>
            ))
          }
        </OptionGroup>

        <h2 style={{ color: text, margin: 0, marginTop: 20 }}>Categorias</h2>
        <br />

        <div onClick={openCategoryModal} style={{ cursor: "pointer", borderRadius: 10, marginBottom: 10, padding: 10, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: main.hex(), color: 'white' }}>
          Criar categoria
        </div>

        <OptionGroup>
          {
            [{ name: "Nenhum" }, ...categories].map((item) => (
              <OptionGroupItem
                key={item.name}
                item={item}
                updateCategory={updateCategory}
                deleteCategory={deleteCategory}
              />
            ))
          }
        </OptionGroup>
      </div>
    </Drawer>
  );
}

export default DrawerComponent;