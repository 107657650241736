import React from 'react';

import PasswordReset from './PasswordReset';
import EmailReset from './EmailReset';

function Break () {
  const HEIGHT = 30;
  return <div style={{ height: HEIGHT }} />;
}

function Security() {
  return (
    <div>
      <EmailReset />
      <Break />
      <PasswordReset />
    </div>
  );
}

export default Security;