import React, { useContext } from 'react';
import styled from 'styled-components';

import ThemeContext from '../../../../context/ThemeContext';

export const Badge = ({ children, onClick }) => {
  const { main, surface } = useContext(ThemeContext);

  return (
    <BadgeComponent
      onClick={onClick}
      style={{ color: surface.hex(), backgroundColor: main.hex() }}
    >
      { children }
    </BadgeComponent>
  );
}

const BadgeComponent = styled.span`
  align-items: center;
  border-radius: 20px;
  display: flex;
  font-size: 13px;
  font-weight: 500;
  justify-content: center;
  padding: 0px 12px;
  line-height: 25px;
  text-align: center;
  text-align-vertical: center;
  user-select: none;
`;

export const OrderItem = ({ children, onClick }) => {
  const { surface } = useContext(ThemeContext);
  
  return (
    <OrderContainer onClick={onClick} color={surface}>
      { children }
    </OrderContainer>
  );
}

const OrderContainer = styled.article`
  align-items: center;
  border-bottom: 1px solid ${props => props.color};
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  padding: 0px 15px;

  &:hover {
    background-color: ${props => props.color};
  }
`;

export const Text = ({ children }) => {
  const { text } = useContext(ThemeContext);

  return (
    <span style={{ userSelect: 'none', color: text }}>{ children }</span>
  )
}