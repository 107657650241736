import React, { useContext } from 'react';

import Search from './Search';
import InfoList from './InfoList';
import Header from './Header';
import OrderItem from './OrderItem';

import ThemeContext from '../../../context/ThemeContext';
import OrdersContext from '../context';

function OrdersList() {
  const { text } = useContext(ThemeContext);
  const { orders } = useContext(OrdersContext);

  return (
    <div style={{ flex: 1, marginRight: 15, alignSelf: 'stretch', display: 'flex', flexDirection: 'column' }}>
      <Search />
      <InfoList />
      <Header />
      {
        orders[0]
        ? (
            <>
              {
                orders.map(item => (
                  <OrderItem order={item} key={item.identifier} /> 
                ))
              }
            </>
          )
        : (
          <div style={{ placeSelf: 'center', flex: 1, display: 'flex', flexDirection: 'column', placeContent: 'center' }}>
            <span style={{ color: text }}>Nenhum resultado</span>
          </div>  
        )
      }
    </div>
  );
}

export default OrdersList;