import React, { useContext } from 'react';
import { TextInput } from './styles';

import { Icon } from '@mdi/react';
import { mdiMagnify } from '@mdi/js';

import ThemeContext from '../../../../context/ThemeContext';
import OrdersContext from '../../context';

function Search() {
  const { text } = useContext(ThemeContext);
  const { search, setSearch } = useContext(OrdersContext);

  const handleSearchChange = (e) => setSearch(e.target.value);

  return (
    <div style={{ alignItems: 'center', display: 'flex', padding: 15, marginBottom: 15 }}>
      <Icon path={mdiMagnify} size={1} color={text.hex()} />
      <TextInput
        value={search}
        onChange={handleSearchChange}
        placeholder="Cliente, código ou produtos"
        style={{ color: text }}
      />
    </div>
  );
}

export default Search;