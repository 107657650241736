import React, { useContext } from 'react';

import Search from './Search';
import Header from './Header';
import ProductItem from './ProductItem';

import ThemeContext from '../../../context/ThemeContext';
import ProductContext from '../context';

function ProductList({ createProduct }) {
  const { text } = useContext(ThemeContext);
  const { products } = useContext(ProductContext);

  return (
    <div style={{ flex: 1, marginRight: 15, alignSelf: 'stretch', display: 'flex', flexDirection: 'column', paddingBottom: 80 }}>
      <Search createProduct={createProduct} />
      <Header />
      {
        products[0]
        ? (
            <>
              {
                products.map(item => (
                  <ProductItem product={item} key={item.name} /> 
                ))
              }
            </>
          )
        : (
          <div style={{ placeSelf: 'center', flex: 1, display: 'flex', flexDirection: 'column', placeContent: 'center' }}>
            <span style={{ color: text }}>Nenhum resultado</span>
          </div>  
        )
      }
    </div>
  );
}

export default ProductList;