import React, { useContext } from 'react';
import { Button } from '@material-ui/core';
import OptionGroup from './OptionGroup';

import ThemeContext from '../../../../../context/ThemeContext';
import ProductContext from '../../../context';
import { ProductOptionsContext } from '../index';

function OptionList() {
  const { soft, text } = useContext(ThemeContext);
  const { selected } = useContext(ProductContext);
  const { showOptGrp } = useContext(ProductOptionsContext);
 
  return (
    <div style={{ color: text.hex() }}>
      <div style={{ marginBottom: 20 }}>
        <Button
          onClick={showOptGrp}
          style={{ backgroundColor: soft.hex(), color: text.hex() }}
          variant="contained"
          disableElevation
        >Adicionar grupo</Button>
        <span style={{ marginLeft: 20 }}>
          { `${selected.options?.length} ${selected.options?.length === 1 ? 'grupo ativo' : 'grupos ativos'}` }
        </span>
      </div>


      {
        selected.options?.map((optionGroup, index) => (
          <OptionGroup style={({ marginTop: index > 0 ? 10 : 0 })} key={optionGroup.optionGroupId} optionGroup={optionGroup} />
        ))
      }
    </div>
  );
}

export default OptionList;
