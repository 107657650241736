import React, { useContext, useState } from 'react';
import { formatDistanceToNow } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import ThemeContext from '../../../../context/ThemeContext';
import OrdersContext from '../../context';

import defaultImage from '../../../../assets/images/default_user.png'

function UserDetails() {
  const { text } = useContext(ThemeContext);
  const { selected } = useContext(OrdersContext);
  const [userImage, setUserImage] = useState(selected.user.image ?? defaultImage)
  
  if (!selected) {
    return <></>
  }
  
  const onImageError = () => {
    setUserImage(defaultImage)
  }

  return (
    <div style={{ marginBottom: 20, marginTop: 20, display: 'flex', alignItems: 'center' }}>
      <img
        onError={onImageError}
        style={{ width: 50, height: 50, borderRadius: 25 }}
        src={userImage}
        alt={selected.user.name}
      />
      <div style={{ marginLeft: 20 }}>
        <p style={{ fontSize: 16 }}>{ selected.user.name }</p>
        <p style={{ fontSize: 13, marginTop: -15, color: text.fade(0.5) }}>
          Há { formatDistanceToNow(parseInt(selected.createdAt), { locale: ptBR }) }
        </p>
      </div>
    </div>
  );
}

export default UserDetails;