import React, { createContext, useContext, useEffect, useState } from 'react';

import AuthContext from '../../context/AuthContext';
import { useFetch } from '../../hooks';

const default_client = {
  andresses: null,
  businessHours: null,
  forceClose: 0
}

const SettingsContext = createContext({
  isLoading: true,
  client: default_client,
  setClient: () => null,
  actualPage: 0,
  setActualPage: () => null,
});

export const SettingsContextProvider = ({ children }) => {
  const { token } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(true);
  const [actualPage, setActualPage] = useState(0);
  const [client, setClient] = useState(default_client);

  useEffect(() => useFetch.post('/a/cli', { token }, (response) => {
    setClient(response[0]);
    setIsLoading(false);
  }), [token]);

  return (
    <SettingsContext.Provider value={{ isLoading, client, setClient, actualPage, setActualPage }}>
      { children }
    </SettingsContext.Provider>
  );
}

export default SettingsContext;
