import React, { useContext, useEffect, useRef, useState } from 'react';
import { PieChart, Pie, Tooltip, Cell } from 'recharts';

import ThemeContext from '../../../../context/ThemeContext';
import StatisticsContext from '../../context';

import { colors } from '../colors';

const Products = () => {
  const { muted, surface, text } = useContext(ThemeContext);
  const { orders: contextOrders } = useContext(StatisticsContext);

  const [orders, setOrders] = useState([]);

  useEffect(() => {

    const reduced = Object.values(contextOrders.filter((item) => [0,1,2,3].some(status => status === item.status)).reduce((obj, item) => {
      const { orders: products } = item;

      products.forEach((product) => {
        const { title, quantity } = product;
        
        if (obj[title]) {
          obj[title].quantity += quantity;
        } else {
          obj[title] = { name: title, quantity};
        }
      });

      return obj;
    }, []));

    setOrders(reduced);
  }, [contextOrders]);

  const containerRef = useRef();

  return (
    <div style={{ backgroundColor: surface.hex() }} ref={containerRef} className="circle-one">
      <div style={{ marginTop: 10, marginBottom: -90, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}>
        <h2 style={{ color: text, marginBottom: 15 }}>Produtos</h2>
        <span style={{ color: muted }}>Os produtos mais vendidos</span>
        <small style={{ color: muted }}>* Passe o mouse para mais detalhes</small>
      </div>

      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
        <PieChart width={240} height={300}>
          <Pie
            data={orders.slice(0, 10).sort((a, b) => a.quantity < b.quantity ? 1 : -1)}
            cx={120}
            cy={200}
            innerRadius={60}
            outerRadius={80}
            fill="#8884d8"
            dataKey="quantity"
          >
            {
              orders.map((entry, index) => <Cell stroke="transparent" key={`cell-${index}`} fill={colors[index]} />)
            }
          </Pie>
          <Tooltip />
        </PieChart>
      </div>
      
    </div>
  );  
}

export default Products;